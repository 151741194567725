import React from "react";

import type { IconProps } from "../icons.type";

function PlayIcon(props: IconProps) {
  const { size = 24 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      fill="none"
      viewBox="0 0 13 16"
    >
      <path stroke="currentColor" d="M0.5 0.5H3.5V15.5H0.5z"></path>
      <path stroke="currentColor" d="M9.5 0.5H12.5V15.5H9.5z"></path>
    </svg>
  );
}

export default PlayIcon;
