import React, { FC } from "react";

type CircleCounterProps = {
  limit: number;
  offset: number;
};

const CircleCounter: FC<CircleCounterProps> = (props) => {
  const { limit, offset } = props;
  const fillCircleValue = (offset / limit) * 100;

  function circleFillColorDetector(fillCircleValue: number) {
    if (fillCircleValue <= 80) return "stroke-success";
    if (fillCircleValue <= 40) return "stroke-danger";
    return "stroke-error";
  }

  return (
    <div className="relative w-[24px] h-[24px] flex items-center justify-center">
      <svg
        className="absolute -rotate-90"
        id="svg"
        width="24"
        height="24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className={circleFillColorDetector(fillCircleValue)}
          strokeWidth={2}
          r="11"
          cx="12"
          cy="12"
          fill="transparent"
          strokeDasharray="85"
          strokeDashoffset={fillCircleValue}
        ></circle>
      </svg>
      <span className="absolute top=0 text-base_xl font-bold">
        {limit - offset}
      </span>
    </div>
  );
};

export default CircleCounter;
