import React from "react";

import type { IconProps } from "../icons.type";

function ChevronRight(props: IconProps) {
  const { size = 24 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      fill="none"
      viewBox="0 0 12 20"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.013 11.831l1.818-1.818-.012-.012-1.818-1.819L1.818 0 0 1.818l8.183 8.183-8.181 8.18L1.82 20 10 11.82l.013.011z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default ChevronRight;
