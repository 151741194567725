import React, { FC } from "react";

import { MdDownload } from "react-icons/md";

import { Item } from "../../models/item.type";

import useFileTypeQualifier from "../../hooks/use-file-type-qualifier";

import noPreviewImage from "../../resources/images/media.png";
import VideoImage from "../../resources/images/video.png";
import Video360Image from "../../resources/images/video-360.png";
import PhotoImage from "../../resources/images/photo.png";

export type FileItem = Item & { check: boolean };

type MediaFileProps = {
  item: FileItem;
};

const MediaFile: FC<MediaFileProps> = (props) => {
  const { item } = props;
  const qualifier = useFileTypeQualifier();

  let imagePath;
  if (qualifier.isVideo(item.file_link)) {
    if (item.file_link.includes(".360.")) {
      imagePath = Video360Image;
    } else {
      imagePath = VideoImage;
    }
  } else {
    imagePath = PhotoImage;
  }

  const renderFile = (
    <div>
      <img
        onError={(event) => changeImageIfExists(event)}
        className="object-fill w-full h-full"
        src={imagePath ? imagePath : noPreviewImage}
        alt=""
      />
    </div>
  );

  function changeImageIfExists(event) {
    event.target.src = noPreviewImage;
  }

  return (
    <div className="w-[195px] h-[110px] bg-light_gray relative overflow-hidden opacity-90 hover:opacity-100 transition">
      {renderFile}
      <div className="absolute right-[10px] bottom-[10px] opacity-90 z-10  rounded-[100%] overflow-hidden">
        <div className="cursor-pointer hover:shadow-popup transition">
          <a href={item.file_link}>
            <div className="bg-[#000] w-[35px] h-[35px] rounded-[100%] flex justify-center items-center cursor-pointer">
              <MdDownload color={"white"} size={20} />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default MediaFile;
