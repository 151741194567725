import React from "react";

import type { IconProps } from "../icons.type";

function InstagramIcon(props: IconProps) {
  const { size = 24 } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} viewBox="0 0 256 256">
      <g
        fill="currentColor"
        strokeMiterlimit="10"
        strokeWidth="0"
        transform="matrix(.72 0 0 .72 128 128) matrix(3.89 0 0 3.89 -175.05 -175.05)"
      >
        <path d="M62.679 90H27.322C12.256 90 0 77.744 0 62.679V27.322C0 12.256 12.256 0 27.322 0h35.357C77.744 0 90 12.256 90 27.322v35.357C90 77.744 77.744 90 62.679 90zM27.322 5C15.014 5 5 15.014 5 27.322v35.357C5 74.986 15.014 85 27.322 85h35.357C74.986 85 85 74.986 85 62.679V27.322C85 15.014 74.986 5 62.679 5H27.322z"></path>
        <path d="M45.261 69.999c-13.641 0-24.738-11.098-24.738-24.738 0-13.641 11.098-24.738 24.738-24.738C58.902 20.523 70 31.621 70 45.261c0 13.64-11.098 24.738-24.739 24.738zm0-44.476c-10.884 0-19.738 8.854-19.738 19.738 0 10.883 8.854 19.738 19.738 19.738C56.146 64.999 65 56.145 65 45.261s-8.854-19.738-19.739-19.738z"></path>
        <circle cx="71.175" cy="19.335" r="5.015"></circle>
      </g>
    </svg>
  );
}

export default InstagramIcon;
