export const API_URL = process.env.REACT_APP_API_SERVER;
export const REQUEST_PREFIX = process.env.REACT_APP_REQUEST_PREFIX;
export const REQUESTS = {
  base: "",
  users: "users/me/",
  packages: "packages/",
  package: "package/",
  messages: "messages/",
  items: "items/",
  tickets: "tickets/",
  tags: "tags/",
  tag: "tag/",
  parks: "parks/",
  attractions: "attractions/",
};
