import React, { FC, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";

import { Box, Button, Divider, Icon, Modal } from "../ui";
import { IconName } from "../ui/icons/icons.type";
import useWindowSize from "../../hooks/use-window-size";
import { Item } from "../../models/item.type";
import { pages } from "../../utils/routes";
import { FeedbackForm } from "../index";
import Rating from "../rating/rating";
import { Package } from "../../models/package.type";

import { useUpdatePackageMutation } from "../../store/api/package.api";

import File from "./file";
import BoxPreview from "./box-preview";
import { iconNavigationClassName } from "./file-preview.styles";

type ChevronPositions = "left" | "right";

type FilePreviewProps = {
  files: Array<Item>;
  pack: Package;
};

const FilePreview: FC<FilePreviewProps> = (props) => {
  let { files, pack } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [openFeedBack, setOpenFeedBack] = useState(false);
  const [currentRating, setCurrentRating] = useState(pack.rating);
  const sliderRef = useRef<Slider | null>(null);
  const { width } = useWindowSize();
  const [changeRating] = useUpdatePackageMutation();
  files = files.filter((file) => file.type !== 5);
  const settings = {
    dots: false,
    speed: 500,
    slidesToScroll: 1,
    autoplay: false,
    infinite: files.length > 3,
  };

  const renderButtonAllContent = (
    <NavLink to={`${pages.FILES}/${pack.id}`}>
      <Button
        width={150}
        padding={25}
        label="Скачать медиапакет"
        variant="fill"
      />
    </NavLink>
  );

  const handleChangeRatingAndOpenFeedBack = async (rating: number) => {
    await changeRating({ id: pack.id, rating }).unwrap();
    setCurrentRating(rating);
    setOpenFeedBack(true);
  };

  const handleChangeSlide = (position: ChevronPositions) => {
    if (position == "left") {
      sliderRef?.current.slickPrev();
      currentIndex > 0
        ? setCurrentIndex(currentIndex - 1)
        : setCurrentIndex(files.length - 1);
    }
    if (position == "right") {
      sliderRef?.current.slickNext();
      currentIndex < files.length - 1
        ? setCurrentIndex(currentIndex + 1)
        : setCurrentIndex(0);
    }
  };

  const renderIconNavigation = (position: ChevronPositions) => {
    const iconType: Record<ChevronPositions, IconName> = {
      left: "ChevronLeft",
      right: "ChevronRight",
    };

    return (
      <div
        className={iconNavigationClassName}
        onClick={() => handleChangeSlide(position)}
      >
        <Icon name={iconType[position]} size={10} />
      </div>
    );
  };

  return (
    <div className="w-full max-w-[1024px]">
      <Box className="w-full max-w-[1024px] relative ">
        <BoxPreview file={files[currentIndex].file_link} />
        <div className="relative overflow-hidden overflow">
          <div className="absolute h-full desktop:hidden flex items-center left-[15px] z-50">
            {sliderRef !== null && renderIconNavigation("left")}
          </div>
          <div className="p-[10px]">
            <Slider
              ref={sliderRef}
              {...{
                ...settings,
                slidesToShow: width > 1024 ? 5 : width > 615 ? 4 : 3,
              }}
            >
              {files.map((file, index) => (
                <File
                  key={file.id}
                  file={file}
                  isSelect={files[currentIndex].id === file.id}
                  onClick={setCurrentIndex.bind(null, index)}
                />
              ))}
            </Slider>
          </div>
          <div className="absolute h-full desktop:hidden flex items-center top-0 right-[15px] z-50">
            {sliderRef !== null && renderIconNavigation("right")}
          </div>
        </div>
        <Divider />
        <div className="flex flex-col-reverse tablet:flex-row items-center tablet:items-start justify-between my-[30px] mx-[20px]">
          <div className="my-[30px] tablet:my-0 flex tablet:mt-[15px] justify-center items-center tablet:items-start tablet:justify-start w-[160px]">
            <div className="w-min">
              <div onClick={setOpenFeedBack.bind(null, true)}>
                <Rating
                  ratingCount={currentRating}
                  onRatingChange={handleChangeRatingAndOpenFeedBack}
                />
              </div>
            </div>
          </div>
          <div className="flex-1 hidden tablet:flex mb-[30px] flex justify-center">
            {renderButtonAllContent}
          </div>
          <div className="w-[160px] flex justify-center tablet:mb-[30px]">
            <NavLink to={pages.HELP}>
              <Button
                size="large"
                padding={23}
                label="Вопросы по видео?"
                variant="outlined"
              />
            </NavLink>
          </div>
          <div className="flex-1 block tablet:hidden mb-[10px] flex justify-center">
            {renderButtonAllContent}
          </div>
        </div>
        <div className="hidden desktop:flex absolute top-0 left-[-45px] h-full justify-center items-center z-80">
          {sliderRef !== null && renderIconNavigation("left")}
        </div>
        <div className="hidden desktop:flex absolute top-0 right-[-45px] h-full justify-center items-center z-80">
          {sliderRef && renderIconNavigation("right")}
        </div>
      </Box>
      <Modal isShow={openFeedBack} onShow={setOpenFeedBack}>
        <Box className="bg-white px-[25px] py-[30px]">
          <FeedbackForm title="Оставить отзыв" package_id={pack.id} />
        </Box>
      </Modal>
    </div>
  );
};

export default FilePreview;
