import React from "react";

import type { IconProps } from "../icons.type";

function WhatsUpIcon(props: IconProps) {
  const { size = 24 } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} viewBox="0 0 256 256">
      <g fill="currentColor" strokeMiterlimit="10" strokeWidth="0">
        <path
          d="M2.192 90a1.999 1.999 0 01-1.929-2.529l5.831-21.287A44.639 44.639 0 01.55 44.657v-.045C.56 20.013 20.581 0 45.179 0c11.931.005 23.142 4.652 31.57 13.084 8.426 8.434 13.063 19.644 13.06 31.563-.012 24.601-20.032 44.615-44.629 44.615a44.732 44.732 0 01-20.639-5.056L2.699 89.935a1.998 1.998 0 01-.507.065zm22.599-9.926c.332 0 .662.083.958.244a40.711 40.711 0 0019.414 4.944c22.41 0 40.637-18.221 40.646-40.616.004-10.852-4.219-21.057-11.89-28.735C66.247 8.235 56.04 4.005 45.178 4 22.799 4 4.581 22.196 4.549 44.572v.043a40.633 40.633 0 005.423 20.304 2 2 0 01.197 1.528l-5.133 18.74 19.247-5.047c.167-.044.338-.066.508-.066z"
          transform="matrix(.72 0 0 .72 128 128) matrix(3.89 0 0 3.89 -175.05 -175.05)"
        ></path>
        <path
          d="M56.645 67.087c-1.983 0-5.031-.508-11.316-2.986-6.823-2.69-13.531-8.545-18.89-16.485l-.177-.261c-1.432-1.915-4.743-6.914-4.743-12.208 0-5.696 2.846-8.731 4.062-10.027l.192-.207c1.637-1.788 3.563-1.981 4.315-1.981h.135c.694.006 1.385 0 1.993.027 1.396.057 2.983.459 4.134 3.018l.022.051c.562 1.255 1.468 3.488 2.198 5.286.435 1.072.928 2.287 1.032 2.504.631 1.264.661 2.493.084 3.646l-.143.289c-.295.602-.574 1.171-1.189 1.892-.172.2-.347.414-.522.626-.392.476-.796.969-1.185 1.356.854 1.427 2.741 4.289 5.377 6.639 3.162 2.819 5.869 3.994 7.324 4.626a20.265 20.265 0 01.863.392c.938-1.08 2.528-2.987 3.063-3.79 1.627-2.44 3.893-1.615 4.746-1.304 1.245.453 7.427 3.524 7.487 3.555l.555.271c.958.463 1.786.862 2.309 1.732.844 1.415.356 4.498-.453 6.766-1.172 3.286-6.202 6.138-8.892 6.383-.171.015-.34.035-.514.056-.55.065-1.137.134-1.867.134zM30.408 26.932h-.321c-.174 0-.785.049-1.365.683l-.225.241c-1.042 1.11-2.979 3.178-2.979 7.292 0 4.208 3.014 8.565 3.938 9.801.062.082.159.223.299.431 4.911 7.277 10.962 12.604 17.041 15.001 7.665 3.022 9.653 2.792 11.247 2.601.21-.024.414-.048.619-.067 1.455-.132 4.938-2.2 5.487-3.743.519-1.452.656-2.674.662-3.312-.151-.076-.33-.163-.488-.239l-.604-.295c-1.717-.857-6.319-3.102-7.069-3.375l-.169-.06c-1.005 1.439-3.384 4.177-3.409 4.204-.858.983-2.37 1.91-4.709.74-.15-.075-.356-.163-.608-.272-1.542-.669-4.751-2.063-8.393-5.309-3.27-2.916-5.511-6.47-6.323-7.862-1.409-2.416.243-4.059.786-4.599.247-.246.588-.661.917-1.062.193-.235.386-.469.572-.687.285-.334.395-.559.636-1.051l.129-.262a1.946 1.946 0 00-.061-.135c-.136-.271-.409-.939-1.156-2.779-.728-1.792-1.633-4.023-2.163-5.201l-.018-.041c-.233-.505-.388-.632-.39-.633.022.022-.139.016-.246.012a40.738 40.738 0 00-1.637-.022z"
          transform="matrix(.72 0 0 .72 128 128) matrix(3.89 0 0 3.89 -175.05 -175.05)"
        ></path>
      </g>
    </svg>
  );
}

export default WhatsUpIcon;
