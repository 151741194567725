import React, { FC, useState } from "react";

import Tooltip from "../ui/tooltip/tooltip";
import { Icon } from "../ui";

type RatingProps = {
  ratingCount: number;
  onRatingChange: (rating: number) => void;
};

const Rating: FC<RatingProps> = (props) => {
  const { ratingCount, onRatingChange } = props;
  const [ratingSelected, setRatingSelected] = useState(ratingCount);
  const reviewTooltip = (
    <div className="text-center py-[1px]">
      <h1 className="text-base underline">Оставить отзыв?</h1>
      <p className="text-little p-0 m-0">С его помощью мы становимся лучше</p>
    </div>
  );

  function handleChangeRating() {
    onRatingChange?.(ratingSelected);
  }

  function ratingReset() {
    setRatingSelected(ratingCount);
  }

  function starRating() {
    const renderRating = Array(5)
      .fill(<Icon name="Star" size={16} className="text-yellow" />)
      .map((star, index) => {
        return (
          <div
            key={index}
            onMouseEnter={setRatingSelected.bind(null, index + 1)}
          >
            <Icon
              name={ratingSelected > index ? "StarFill" : "Star"}
              size={16}
              className="text-yellow"
            />
          </div>
        );
      });
    return (
      <div
        className="inline-flex gap-[5px] cursor-pointer"
        onMouseLeave={ratingReset.bind(null)}
        onClick={handleChangeRating}
      >
        {renderRating}
      </div>
    );
  }

  return (
    <Tooltip width={152} description={reviewTooltip}>
      {starRating()}
    </Tooltip>
  );
};

export default Rating;
