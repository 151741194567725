import React, { FC, useEffect, useState } from "react";

import { NavLink } from "react-router-dom";

import { Box, Button, Divider } from "../ui";
import { Package } from "../../models/package.type";
import { Attraction } from "../../models/attraction.type";
import useHumanTimeLocale from "../../hooks/use-human-time-locale";

import { pages } from "../../utils/routes";

import MediaFile, { FileItem } from "./media-file";

type FileListProps = {
  pack: Package;
  attraction: Attraction;
};

const FileList: FC<FileListProps> = (props) => {
  const { pack, attraction } = props;
  const [checkedList, setCheckedList] = useState<Array<FileItem>>([]);
  const humanTime = useHumanTimeLocale();

  useEffect(() => {
    if (pack.package_items) {
      const items: Array<FileItem> = new Array(pack.package_items.length)
        .fill("")
        .map((_, index) => ({ ...pack.package_items[index], check: false }));
      setCheckedList(items);
    }
  }, [pack.package_items]);

  const renderFiles = checkedList.map((item, index) => (
    <MediaFile key={index} item={item} />
  ));

  return (
    <Box>
      <div className="flex flex-col tablet:flex-row justify-between items-center px-[15px] tablet:truncate py-[27px]">
        <div className="ml-[10px] text-center tablet:ml-[15px] mb-[17px] tablet:w-[100px]  tablet:mb-0">
          <h2 className="text-large tablet:flex font-bold p-0 m-0 leading-3 pb-[6px]">
            {attraction.name}
          </h2>
          <div className="text-base tablet:flex font-normal pl-0 ml-0">
            {humanTime.dateMDY(pack.date_time_creation)}
          </div>
        </div>
        <div>
          <NavLink to={pages.HELP}>
            <Button
              variant="outlined"
              padding={22}
              size="large"
              label="Вопросы по видео?"
            />
          </NavLink>
        </div>
      </div>
      <Divider />
      <div className="flex flex-wrap gap-1 justify-center px-[15px] py-[20px]">
        {renderFiles}
      </div>
      <Divider />
    </Box>
  );
};

export default FileList;
