import clsx from "clsx";

export const iconNavigationClassName = clsx(
  "w-[35px]",
  "h-[35px]",
  "rounded-[100%]",
  "bg-white",
  "text-success",
  "cursor-pointer",
  "flex",
  "justify-center",
  "items-center",
  "z-20"
);

export const filePreviewClassName = (selected: boolean) =>
  clsx(
    "w-[98%]",
    "h-[80px]",
    "mobile:h-[109px]",
    "mx-[2px]",
    "bg-light_gray",
    "cursor-pointer",
    "overflow-hidden",
    "transition",
    "opacity-80",
    "hover:opacity-100",
    selected && "border-[1px] border-primary opacity-100"
  );

export const filePreviewImageClassName = clsx(
  "w-[100%]",
  "h-[80px]",
  "mobile:h-[109px]",
  "object-fit"
);

export const boxPreviewClassName = clsx(
  "w-full",
  "h-full",
  "mx-auto",
  "tablet:h-[575px]",
  "overflow-hidden",
  "bg-gradient-to-r",
  "from-gray",
  " via-white",
  "to-gray"
);
