import React from "react";

import { Divider } from "../index";

import LangSwitch from "./lang-switch";
import HeaderLogo from "./header-logo";

const SimpleHeader = () => {
  return (
    <>
      <div className="flex justify-center h-[100px] z-20">
        <div className="relative max-w-[1040px] w-full flex  justify-between tablet:justify-center items-center">
          <div className="inline-flex justify-start items-center ml-[15px] desktop:ml-0">
            <HeaderLogo />
          </div>
          <LangSwitch />
        </div>
      </div>
      <Divider />
    </>
  );
};

export default SimpleHeader;
