import React, { createContext, ReactNode, useContext } from "react";

import { useGetUserQuery } from "../store/api/user.api";

import { useGetAuthQuery } from "../store/api/auth.api";

import { appConfig } from "./constants";

const authContext = createContext(null);
export const useAuth = () => useContext(authContext);
const useProvideAuth = () => {
  useGetAuthQuery(localStorage.getItem(appConfig.AUTH_LINK));
  const userQuery = useGetUserQuery("");
  const logout = () => {
    localStorage.removeItem(appConfig.AUTH_LINK);
    localStorage.removeItem(appConfig.TOKEN_NAME);
    window.location.reload();
  };
  return {
    user: userQuery.data,
    userLoading: userQuery.isLoading,
    userSuccess: userQuery.isSuccess,
    logout,
  };
};
export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};
