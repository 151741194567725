import clsx from "clsx";

export const buttonClassName = (
  isFullWidth: boolean,
  variant: string,
  size: string
) =>
  clsx(
    "leading-3",
    "rounded-[5px]",
    "text-base",
    "font-semibold",
    "px-[5px]",
    "flex",
    "justify-center",
    "items-center",

    size === "large" ? "py-[14px]" : "py-[10px]",
    "transition",
    variant === "fill"
      ? "hover:bg-primary50 bg-primary text-bg_gray disabled:bg-gray"
      : "hover:border-primary50 hover:text-primary50 bg-transparent border-[1px] border-primary text-primary disabled:border-gray disabled:text-gray",
    variant === "fill"
      ? "active:bg-primary100"
      : "active:border-primary100 active:text-primary100",
    isFullWidth && "w-full",
    variant
  );

export const iconButtonTooltipClassName = clsx(
  "group-hover:flex",
  "transition",
  "hidden",
  "border-[1px]",
  "absolute",
  "left-[calc(100%+16px)]",
  "border-error",
  "rounded-small",
  "p-[5px]",
  "bg-white",
  "min-h-[25px]",
  "w-fit",
  "w-[132px]",
  "text-small",
  "font-normal",
  "before:content-['']",
  "before:top-[calc(50%-5px)]",
  "before:left-[-11px]",
  "before:absolute",
  "before:border-[5px]",
  "before:border-transparent",
  "before:border-r-[5px]",
  "before:border-r-error",
  "after:content-['']",
  "after:top-[calc(50%-5px)]",
  "after:left-[-10px]",
  "after:absolute",
  "after:border-[5px]",
  "after:border-transparent",
  "after:border-r-[5px]",
  "after:border-r-white"
);

export const flatButtonClassName = clsx(
  "w-[100px]",
  "group",
  "h-[100px]",
  "flex",
  "flex-col",
  "items-center",
  "shadow-popup",
  "bg-white",
  "rounded-middle",
  "text-primary",
  "hover:text-white",
  "hover:bg-primary50",
  "active:text-white",
  "active:bg-primary100"
);
