import React, { FC, ReactNode } from "react";

import { iconButtonTooltipClassName } from "./button.styles";

type IconButtonProps = {
  icon: ReactNode;
  tooltip: string;
};

const IconButton: FC<IconButtonProps> = (props) => {
  const { icon, tooltip } = props;
  return (
    <div className="relative inline-flex items-center group cursor-pointer">
      {icon}
      <div className={iconButtonTooltipClassName}>
        <div>{tooltip}</div>
      </div>
    </div>
  );
};

export default IconButton;
