import React from "react";

import type { IconProps } from "../icons.type";

function XIcon(props: IconProps) {
  const { size = 24 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.182 20L20 18.182 11.818 10 20 1.818 18.182 0 10 8.182 1.818 0 0 1.818 8.182 10 0 18.182 1.818 20 10 11.818 18.182 20z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default XIcon;
