import React from "react";

import type { IconProps } from "../icons.type";

function YoutubeIcon(props: IconProps) {
  const { size = 24 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      fill="none"
      viewBox="0 0 22 17"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M21.025 14.717c.683-1.176.975-3.072.975-6.156 0-3.072-.29-4.957-.98-6.165C20.328 1.143 19.507.745 17.878.65 16.766.573 14.002.531 11.003.531 7.997.53 5.23.573 4.11.65c-1.62.095-2.44.494-3.14 1.751C.292 3.604 0 5.498 0 8.572c0 3.056.293 4.96.978 6.16.689 1.24 1.497 1.634 3.13 1.75 1.175.069 4.096.115 6.895.115 2.792 0 5.712-.046 6.875-.115 1.649-.116 2.457-.51 3.147-1.765zM17.752 2.653c1.02.06 1.208.152 1.531.74.47.817.717 2.417.717 5.18 0 2.74-.248 4.35-.715 5.153-.324.589-.511.68-1.535.752-1.101.065-3.993.11-6.747.11-2.76 0-5.654-.045-6.766-.11-1.011-.072-1.201-.165-1.518-.735C2.249 12.92 2 11.302 2 8.56c0-2.74.248-4.349.715-5.175.322-.58.513-.673 1.522-.732 1.068-.073 3.807-.115 6.766-.115 2.952 0 5.69.042 6.75.114zM9 5.551l5 3.013-5 3.012V5.551z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default YoutubeIcon;
