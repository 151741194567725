import { createApi } from "@reduxjs/toolkit/query/react";

import { REQUESTS } from "../../utils/api";
import { Park } from "../../models/park.type";

import { baseQueryWithReAuth } from "./baseQuery";

export const parkApi = createApi({
  reducerPath: "parkQuery",
  tagTypes: ["Parks"],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getParks: build.query({
      query: () => REQUESTS.parks,
      providesTags: (result: Array<Park>) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Parks" as const, id })),
              { type: "Parks", id: "LIST" },
            ]
          : [{ type: "Parks", id: "LIST" }],
    }),
    addParks: build.mutation({
      query: (body) => ({
        url: REQUESTS.parks,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Parks", id: "LIST" }],
    }),
    getPark: build.query({
      query: (id: string) => `${REQUESTS.parks}${id}`,
    }),
    deletePark: build.mutation({
      query: (id: string) => ({
        url: `${REQUESTS.parks}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Parks", id: "LIST" }],
    }),
    updatePark: build.mutation({
      query: (id: string) => ({
        url: `${REQUESTS.parks}${id}`,
        method: "PUT",
      }),
      invalidatesTags: [{ type: "Parks", id: "LIST" }],
    }),
  }),
});

export const {
  useDeleteParkMutation,
  useUpdateParkMutation,
  useGetParksQuery,
  useGetParkQuery,
  useAddParksMutation,
} = parkApi;
