import React from "react";

import { Layout } from "../components";
import { Box, Center } from "../components/ui";

const HelpPage = () => {
  const liBoxClassName = "inline-flex items-start my-[5px] text-text_small";
  const dotLimiter = (
    <div>
      <div className="rounded-[100%] w-[5px] h-[5px] bg-primary mr-[10px] mt-[5px]" />
    </div>
  );
  return (
    <Layout>
      <Center>
        <h1 className="text-large w-full text-center font-bold my-[20px]">
          Помощь
        </h1>
        <Box className="w-full  my-[30px] p-[20px] rounded-middle">
          <p className="mb-[20px]">
            С помощью формы личного кабинета пользователю доступны его
            медиапакеты на посещенных активностях заказанные и оплаченные в{" "}
            <b>Skypark Sochi</b>.
          </p>
          <ul className="flex flex-col text-base">
            <li className={liBoxClassName}>
              {dotLimiter}
              Доступны функции просмотра и скачивания медиа контента.
            </li>
            <li className={liBoxClassName}>
              {dotLimiter}
              Хранение медиа контента ограничено по времени, полная информация
              на главной странице.
            </li>
            <li className={liBoxClassName}>
              {dotLimiter}
              Любые вопросы и пожелания по полученному контенту пользователь
              может задать через данную форму , а также по телефону клиентской
              службы в разделе «контакты»."
            </li>
          </ul>
        </Box>
      </Center>
    </Layout>
  );
};

export default HelpPage;
