import moment from "moment";

/**
 * Changes word endings RU locate ["день", "дня", "дней"]
 * @return day (count:number) - count of days; month(date:string) - ending of month ru locale
 */

export default function useHumanTimeLocale() {
  const day = ["день", "дня", "дней"];
  function days(daysCount: number) {
    const lastTimeNumber = Number(daysCount.toString().split("").pop());
    let locale = day[2];
    if (lastTimeNumber === 1 && daysCount != 11) locale = day[0];
    if (lastTimeNumber > 1 && lastTimeNumber < 5) locale = day[1];
    return locale;
  }
  function dateMDY(date: string) {
    const month = [
      "Января",
      "Февраля",
      "Марта",
      "Апреля",
      "Мая",
      "Июня",
      "Июля",
      "Августа",
      "Сентября",
      "Октября",
      "Ноября",
      "Декабря",
    ];
    const currentData = moment(date);
    return `${currentData.date()} ${
      month[currentData.month()]
    } ${currentData.year()}`;
  }
  return {
    days,
    dateMDY,
  };
}
