import { configureStore } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
} from "redux-persist";

import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const persistConfig = {
  key: "rtk:access_token",
  storage,
  whitelist: ["queries", "data"],
};

import { messageApi } from "./api/messages.api";
import { packageApi } from "./api/package.api";
import { itemApi } from "./api/item.api";
import { ticketApi } from "./api/ticket.api";
import { tagApi } from "./api/tag.api";
import { parkApi } from "./api/park.api";
import { attractionApi } from "./api/attraction.api";
import { userApi } from "./api/user.api";
import { authApi } from "./api/auth.api";

const persistedReducer = persistReducer(persistConfig, authApi.reducer);
export const store = configureStore({
  reducer: {
    persistedReducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [messageApi.reducerPath]: messageApi.reducer,
    [packageApi.reducerPath]: packageApi.reducer,
    [itemApi.reducerPath]: itemApi.reducer,
    [ticketApi.reducerPath]: ticketApi.reducer,
    [tagApi.reducerPath]: tagApi.reducer,
    [parkApi.reducerPath]: parkApi.reducer,
    [attractionApi.reducerPath]: attractionApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      messageApi.middleware,
      packageApi.middleware,
      itemApi.middleware,
      ticketApi.middleware,
      tagApi.middleware,
      parkApi.middleware,
      attractionApi.middleware,
      userApi.middleware,
      authApi.middleware
    ),
});
export const persistor = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
