/**
 * @return isVideo : (file: string) => boolean - qualifier what file is a video file (avi, mp4)
 */
export default function useFileTypeQualifier() {
  const videoTypeList = ["avi", "mp4", "MP4"];
  function isVideo(file: string) {
    const fileType = file.split(".").pop();
    return videoTypeList.includes(fileType);
  }
  return {
    isVideo,
  };
}
