import { createApi } from "@reduxjs/toolkit/query/react";

import { REQUESTS } from "../../utils/api";

import { Attraction } from "../../models/attraction.type";

import { baseQueryWithReAuth } from "./baseQuery";

export const attractionApi = createApi({
  reducerPath: "attractionQuery",
  tagTypes: ["Attractions"],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getAttractions: build.query({
      query: () => REQUESTS.attractions,
      providesTags: (result: Array<Attraction>) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Attractions" as const, id })),
              { type: "Attractions", id: "LIST" },
            ]
          : [{ type: "Attractions", id: "LIST" }],
    }),
    addAttraction: build.mutation({
      query: (body) => ({
        url: REQUESTS.attractions,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Attractions", id: "LIST" }],
    }),
    getAttraction: build.query({
      query: (id: string) => `${REQUESTS.attractions}${id}`,
      providesTags: [{ type: "Attractions", id: "LIST" }],
    }),
    deleteAttraction: build.mutation({
      query: (id: string) => ({
        url: `${REQUESTS.attractions}${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Attractions", id: "LIST" }],
    }),
    updateAttraction: build.mutation({
      query: (id: string) => ({
        url: `${REQUESTS.attractions}${id}`,
        method: "PUT",
      }),
      invalidatesTags: [{ type: "Attractions", id: "LIST" }],
    }),
  }),
});

export const {
  useAddAttractionMutation,
  useDeleteAttractionMutation,
  useGetAttractionQuery,
  useGetAttractionsQuery,
  useUpdateAttractionMutation,
} = attractionApi;
