import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";

import { Button, Input } from "../ui";

import { useUpdateUserMutation } from "../../store/api/user.api";
import { UserCommonInfo } from "../../models/user.type";

import {
  formTitleClassName,
  inputBlockClassName,
  inputClassName,
  labelClassName,
} from "./settings-form.styles";

type PersonalFormFields = {
  email: string;
  name: string;
  surname: string;
  phone_number: string;
};

type AccountFormProps = {
  user: UserCommonInfo;
};
const AccountForm: FC<AccountFormProps> = (props) => {
  const { user } = props;
  const defaultValues = {
    email: user.email,
    name: user.name,
    surname: user.surname,
    phone_number: user.phone_number,
  };
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<PersonalFormFields>({
    defaultValues,
  });

  function handleSubmitPersonalForm(data: PersonalFormFields) {
    updateUser({ ...data, id: user.id })
      .unwrap()
      .then(() => {
        toast.success("Профиль обновлен", {
          position: "bottom-right",
          hideProgressBar: true,
        });
      });
  }

  async function handleResetFormAndSave() {
    reset();
  }

  return (
    <form
      onSubmit={handleSubmit(handleSubmitPersonalForm)}
      className="text-center flex flex-col items-center"
    >
      <h1 className={formTitleClassName}>Изменение личных данных</h1>
      <div className={inputBlockClassName}>
        <label className={labelClassName}>Адрес e-mail</label>
        <div className={inputClassName}>
          <Input
            value={defaultValues.email}
            type="text"
            label="Введите email"
            {...register("email", { required: true })}
            error={
              errors.email?.type === "required" && "Поле email не заполнено"
            }
            isCenter
          />
        </div>
      </div>
      <div className={inputBlockClassName}>
        <label className={labelClassName}>Имя</label>
        <div className={inputClassName}>
          <Input
            type="text"
            value={defaultValues?.name}
            label="Укажите имя"
            {...register("name", { required: true })}
            error={errors.name?.type === "required" && "Поле Имя не заполнено"}
            isCenter
          />
        </div>
      </div>
      <div className={inputBlockClassName}>
        <label className={labelClassName}>Фамилия</label>
        <div className={inputClassName}>
          <Input
            type="text"
            value={defaultValues?.surname}
            label="Укажите фамилию"
            {...register("surname")}
            isCenter
          />
        </div>
      </div>
      <div className={inputBlockClassName}>
        <label className={labelClassName}>Номер телефона</label>
        <div className={inputClassName}>
          <Input
            value={defaultValues?.phone_number}
            type="text"
            label="Номер телефона"
            {...register("phone_number")}
            isCenter
          />
        </div>
      </div>
      <div className="inline-flex flex-col mt-[20px] mb-[30px] tablet:flex-row">
        <div className="mb-[10px] tablet:mr-[20px] h-full h-fit">
          <Button
            label="Сохранить изменения"
            type="submit"
            variant="fill"
            size="large"
            height={13}
            padding={15}
            disabled={isLoading}
            isFullWidth
          />
        </div>
        <div className="w-[150px]">
          <Button
            label="Отмена"
            type="button"
            isFullWidth
            height={12}
            padding={15}
            disabled={isLoading}
            variant="outlined"
            onClick={handleResetFormAndSave}
          />
        </div>
      </div>
      <ToastContainer />
    </form>
  );
};

export default AccountForm;
