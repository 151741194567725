import React from "react";

import type { IconProps } from "../icons.type";

function ChevronLeft(props: IconProps) {
  const { size = 24 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      fill="none"
      viewBox="0 0 12 20"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1.818 8.169L0 9.987.012 10V10l1.818 1.818L10.013 20l1.818-1.818L3.65 9.999l8.18-8.18L10.011 0l-8.18 8.181-.013-.012z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default ChevronLeft;
