import React from "react";

import { NavLink } from "react-router-dom";

import { Center, SimpleHeader } from "../components/ui";
import { pages } from "../utils/routes";

const NotFoundPage = () => {
  return (
    <>
      <SimpleHeader />
      <Center>
        <div className="inline-flex items-center my-[30px] justify-center w-full">
          <div className="text-large">
            <b> 404 </b>
          </div>{" "}
          <div> Страница не найдена</div>
        </div>
        <div className="underline text-primary w-full text-center">
          <NavLink to={pages.HOME}>Вернуться на главную</NavLink>
        </div>
      </Center>
    </>
  );
};

export default NotFoundPage;
