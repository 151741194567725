import React from "react";

import type { IconProps } from "../icons.type";

function DoneIcon(props: IconProps) {
  const { size = 24 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      fill="none"
      viewBox="0 0 17 12"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.247 10.013l1.819 1.818.012-.012 1.819-1.818h-.001l8.183-8.183L14.26 0 6.078 8.183l-4.26-4.26L0 5.74l4.26 4.26-.013.012z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default DoneIcon;
