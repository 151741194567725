import React from "react";

import { AccountForm, Layout, LoadingAccountForm } from "../components";
import { Center } from "../components/ui";
import { useGetUserQuery } from "../store/api/user.api";

const SettingsPage = () => {
  const userQuery = useGetUserQuery("");
  return (
    <Layout>
      <Center>
        <div className="w-full flex justify-center mt-[50px] pb-[215px] flex-col">
          {userQuery.isSuccess ? (
            <AccountForm user={userQuery.data} />
          ) : (
            <LoadingAccountForm />
          )}
          {/* <PasswordForm/> */}
        </div>
      </Center>
    </Layout>
  );
};

export default SettingsPage;
