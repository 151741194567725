import React, {
  ChangeEvent,
  FC,
  ForwardedRef,
  forwardRef,
  useEffect,
  useState,
} from "react";

import Icon from "../icons";

type CheckBoxProps = {
  onCheck?: (e: boolean) => void;
  initialState?: boolean;
  name?: string;
  value: string | number;
};

const Checkbox: FC<CheckBoxProps> = forwardRef(
  (props: CheckBoxProps, forwarded: ForwardedRef<HTMLInputElement>) => {
    const { onCheck, initialState, name, value } = props;
    const [isChecked, setIsChecked] = useState(false);

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
      setIsChecked((prev) => !prev);
      onCheck?.(event.target.checked);
      console.log("checked");
    }

    useEffect(() => {
      initialState && setIsChecked(initialState);
    }, [initialState]);

    return (
      <label className="cursor-pointer hover:shadow-popup transition">
        <div className="bg-white w-[30px] h-[30px] rounded-[100%] flex justify-center items-center">
          {isChecked && <Icon name="Done" size={18} className="text-primary" />}
        </div>
        <input
          ref={forwarded}
          className="appearance-none hidden"
          onChange={(event) => handleChange(event)}
          type="checkbox"
          name={name}
          value={value}
          checked={isChecked}
        />
      </label>
    );
  }
);

Checkbox.displayName = "Checkbox";

export default Checkbox;
