import React from "react";

import type { IconProps } from "../icons.type";

function InfoIcon(props: IconProps) {
  const { size = 24 } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} viewBox="0 0 100 100">
      <path
        fill="#78a1d1"
        d="M69.307 81.961h-39c-6.6 0-12-5.4-12-12v-39c0-6.6 5.4-12 12-12h39c6.6 0 12 5.4 12 12v39c0 6.6-5.4 12-12 12z"
      ></path>
      <path
        fill="#1f212b"
        d="M69.307 82.961h-39c-7.168 0-13-5.832-13-13v-39c0-7.168 5.832-13 13-13h39c7.168 0 13 5.832 13 13v39c0 7.168-5.831 13-13 13zm-39-63c-6.065 0-11 4.935-11 11v39c0 6.065 4.935 11 11 11h39c6.065 0 11-4.935 11-11v-39c0-6.065-4.935-11-11-11h-39z"
      ></path>
      <path
        fill="#78a1d1"
        d="M76.807 48.461v18.663c0 5.685-4.652 10.337-10.337 10.337H33.144c-5.685 0-10.337-4.652-10.337-10.337V33.798c0-5.685 4.652-10.337 10.337-10.337h33.663"
      ></path>
      <path
        fill="#1f212b"
        d="M66.47 77.961H33.144c-5.976 0-10.837-4.861-10.837-10.837V33.798c0-5.976 4.861-10.837 10.837-10.837h33.663a.5.5 0 010 1H33.144c-5.424 0-9.837 4.413-9.837 9.837v33.326c0 5.424 4.413 9.837 9.837 9.837H66.47c5.424 0 9.837-4.413 9.837-9.837V48.461a.5.5 0 011 0v18.663c0 5.975-4.861 10.837-10.837 10.837zm10.337-32a.5.5 0 01-.5-.5v-4a.5.5 0 011 0v4a.5.5 0 01-.5.5zm0-6a.5.5 0 01-.5-.5v-2a.5.5 0 011 0v2a.5.5 0 01-.5.5z"
      ></path>
      <path
        fill="#fdfcee"
        d="M53.342 62.596c-.066 1.354 1.359 2.032 4.275 2.032V67.2H42.383v-2.573c2.817 0 4.227-.677 4.227-2.032V48.871c0-1.384-1.409-2.076-4.227-2.076v-2.574h10.959v18.375zm.589-25.959c0 1.054-.401 1.964-1.204 2.732-.803.767-1.794 1.151-2.973 1.151-.59 0-1.139-.105-1.646-.316a4.473 4.473 0 01-1.327-.835 4.055 4.055 0 01-.909-1.219 3.385 3.385 0 01-.344-1.512c0-1.083.418-1.993 1.253-2.731.835-.737 1.826-1.106 2.973-1.106 1.146 0 2.129.377 2.948 1.129.819.751 1.229 1.654 1.229 2.707z"
      ></path>
      <path
        fill="#1f212b"
        d="M57.617 67.7H42.383a.5.5 0 01-.5-.5v-2.573a.5.5 0 01.5-.5c2.473 0 3.727-.515 3.727-1.532V48.871c0-1.046-1.254-1.577-3.727-1.577a.5.5 0 01-.5-.5v-2.573a.5.5 0 01.5-.5h10.959a.5.5 0 01.5.5V62.62c-.015.294.065.521.25.716.344.361 1.235.792 3.525.792a.5.5 0 01.5.5V67.2c0 .277-.223.5-.5.5zm-14.734-1h14.234v-1.58c-1.871-.053-3.1-.413-3.75-1.095-.374-.392-.55-.877-.525-1.441V44.721h-9.959v1.581c2.805.089 4.227.953 4.227 2.569v13.725c0 1.588-1.421 2.436-4.227 2.524v1.58zm10.459-4.104h.01-.01zM49.755 41.02a4.773 4.773 0 01-1.838-.354 4.955 4.955 0 01-1.474-.929 4.593 4.593 0 01-1.02-1.368 3.892 3.892 0 01-.395-1.732c0-1.227.479-2.272 1.422-3.105.926-.817 2.038-1.232 3.305-1.232 1.269 0 2.375.424 3.286 1.26.923.849 1.391 1.884 1.391 3.077 0 1.19-.457 2.23-1.358 3.093-.897.856-2.013 1.29-3.319 1.29zm0-7.721c-1.03 0-1.895.321-2.643.981-.729.644-1.084 1.415-1.084 2.356 0 .462.099.897.292 1.292.199.407.468.768.799 1.071.333.306.73.555 1.18.741.445.185.935.278 1.456.278 1.055 0 1.914-.331 2.627-1.013.706-.675 1.049-1.451 1.049-2.37 0-.915-.349-1.68-1.067-2.341-.73-.668-1.584-.995-2.609-.995z"
      ></path>
    </svg>
  );
}

export default InfoIcon;
