import React from "react";

import { Layout, StubBox } from "../components";
import { Center } from "../components/ui";

const CreateVideoPage = () => {
  return (
    <Layout>
      <Center>
        <div className="w-full flex justify-center my-[30px] items-center flex-col">
          <h1 className="text-large font-bold mb-8">Создать видео</h1>
          <StubBox message="Страница временно недоступна" />
        </div>
      </Center>
    </Layout>
  );
};

export default CreateVideoPage;
