import { createApi } from "@reduxjs/toolkit/query/react";

import { REQUESTS } from "../../utils/api";

import { baseQueryWithoutToken } from "./baseQuery";

export const authApi = createApi({
  reducerPath: "authQuery",
  tagTypes: ["Auth"],
  baseQuery: baseQueryWithoutToken,
  endpoints: (build) => ({
    getAuth: build.query({
      query: (link) => `${REQUESTS.base}${link}`,
      providesTags: () => ["Auth"],
    }),
  }),
});

export const { useGetAuthQuery } = authApi;
