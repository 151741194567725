import { IconName } from "../components/ui/icons/icons.type";

type ButtonItem = {
  icon: IconName;
  label: string;
  link: string;
};

export let buttonList: ButtonItem[] = [];

let env_vars: ButtonItem[] = [
  {
    icon: "Telegram",
    label: "Telegram",
    link: process.env.REACT_APP_TELEGRAM_LINK,
  },
  {
    icon: "Vk",
    label: "VK",
    link: process.env.REACT_APP_VK_LINK,
  },
  {
    icon: "Tripadvisor",
    label: "TripAdvisor",
    link: process.env.REACT_APP_TRIPADVISOR_LINK,
  },
  {
    icon: "Youtube",
    label: "Youtube",
    link: process.env.REACT_APP_YOUTUBE_LINK,
  },
  {
    icon: "WhatsUp",
    label: process.env.REACT_APP_WHATS_UP_LINK,
    link: process.env.REACT_APP_WHATS_UP_LINK,
  },
];

for (let item of env_vars) {
  if (item.link != "") {
    buttonList.push(item);
  }
}
