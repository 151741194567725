import React, { useState, FC, useRef, ChangeEvent } from "react";
import ReactPlayer from "react-player";

import { Icon } from "../";

import VolumeControl from "./volume-control";
import {
  bgControlClassName,
  controlContainerClassName,
  playerClassName,
} from "./player.styles";

type PlayerProps = {
  file: string;
};

const Player: FC<PlayerProps> = (props) => {
  const { file } = props;
  const playerRef = useRef(null);
  const [isPlay, changeIsPlay] = useState<boolean>(false);
  const [duration, setDuration] = useState<number>(0);
  const [played, setPlayed] = useState(0);
  const [volume, setVolume] = useState(1);

  const playerDuration = (duration: number) => {
    setDuration(duration);
  };

  const playerProgress = (progress) => {
    setPlayed(progress.playedSeconds);
  };

  const changePlayerSeek = (e) => {
    const changeToTime = e.target.value;
    playerRef.current.seekTo(Number(changeToTime));
  };

  const onChangeVolume = (event: ChangeEvent<HTMLInputElement>) => {
    setVolume(Number(event.target.value));
  };

  function toMinuteConverter(e) {
    const h = Math.floor(e / 3600)
        .toString()
        .padStart(2, "0"),
      m = Math.floor((e % 3600) / 60)
        .toString()
        .padStart(2, "0"),
      s = Math.floor(e % 60)
        .toString()
        .padStart(2, "0");
    if (h === "00") return m + ":" + s;
    return h + ":" + m + ":" + s;
  }

  return (
    <div className="relative  bg-gradient-to-r from-gray via-white to-gray w-full h-full group overflow-hidden">
      <ReactPlayer
        controls={null}
        ref={playerRef}
        onDuration={(duration) => playerDuration(duration)}
        onProgress={(progress) => playerProgress(progress)}
        width="100%"
        volume={volume}
        height="100%"
        playing={isPlay}
        url={file}
        onClickPreview={setPlayed.bind(null, !isPlay)}
      />
      {!isPlay && (
        <div
          className={playerClassName}
          onClick={changeIsPlay.bind(null, (prev) => !prev)}
        >
          <Icon name="Play" size={20} />
        </div>
      )}
      <div className={bgControlClassName} />
      <div className={controlContainerClassName}>
        <div
          className="cursor-pointer hover:opacity-90"
          onClick={changeIsPlay.bind(null, (prev) => !prev)}
        >
          <Icon name={isPlay ? "Pause" : "Play"} size={16} />
        </div>
        <div className="w-full flex-1 pl-[25px] pr-[18px] mt-[-8px]">
          <input
            type="range"
            value={played}
            className="h-[2px] w-full flex-1 cursor-pointer"
            max={duration}
            min={0}
            onChange={changePlayerSeek}
          />
        </div>
        <div className="inline-flex items-center w-[130px]">
          <div className="text-base_xl font-normal w-[130px] text-center select-none">
            {toMinuteConverter(played)} / {toMinuteConverter(duration)}
          </div>
          <div className="w-[30px] flex-1">
            <VolumeControl
              volume={String(volume)}
              onChangeVolume={onChangeVolume}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Player;
