import React from "react";

import type { IconProps } from "../icons.type";

function Vk(props: IconProps) {
  const { size = 24 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      fill="none"
      viewBox="0 0 51 32"
    >
      <g clipPath="url(#clip0_1_8)">
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M49.8 26c-1.2-2.4-2.7-4.5-4.6-6.4-.6-.7-1.4-1.5-1.9-2l-.1-.1-.7-.7C45 13.3 47.2 9.5 49 5.5V5.2c.2-.8.5-2.3-.4-3.6C47.6.3 46 0 45 0h-4.8c-1 0-2 .2-2.8.8-.9.6-1.5 1.4-1.9 2.3-1 2.5-2.3 4.8-3.9 7V4.5c0-.7-.1-2-.9-3C29.8.3 28.4 0 27.5 0h-7.7c-1 0-1.9.3-2.7 1-.8.7-1.2 1.6-1.3 2.6v.2c0 1 .4 1.8.7 2.3.1.2.3.4.4.5.1.1.2.2.2.3.2.3.5.6.5 1.7v3.2c-1.3-2.2-2.7-5.1-3.8-8.2-.3-.7-.7-1.7-1.5-2.4-.8-.9-2-1.2-3-1.2H4.4C3.3 0 2 .3 1.1 1.3.1 2.3 0 3.5 0 4.1v.3l.1.3C1.4 11 4.2 16.9 8.2 21.9c1.8 2.9 4.3 5.3 7.2 7 3 1.8 6.3 2.8 9.8 3.1h.2c1.6 0 3.2-.1 4.4-.9 1.7-1.1 1.8-2.8 1.8-3.6V25c.4.3.9.8 1.6 1.4.8.8 1.4 1.5 1.9 2l.3.3c.4.5.8.9 1.2 1.3.5.5 1 1 1.8 1.4.8.4 1.6.6 2.5.6h4.9c1 0 2.5-.2 3.6-1.5s1-2.9.7-4.1l-.1-.2-.2-.2zm-12.3.3c-.6-.6-1.2-1.3-2.1-2.2-2.9-2.8-4.3-3.3-5.2-3.3-.5 0-1.1.1-1.4.5-.2.2-.3.5-.3.7-.1.3-.1.6-.1 1v4.4c0 .5-.1.8-.3.9-.3.2-1.1.4-2.6.4-2.9-.2-5.8-1.1-8.3-2.6-2.5-1.5-4.7-3.6-6.2-6.1C7.1 15.3 4.5 9.9 3.2 4c0-.3.1-.4.2-.6.1-.1.3-.3 1-.3h4.9c.5 0 .8.1 1 .3.2.2.4.6.7 1.2 1.2 3.5 2.8 6.8 4.4 9.3.8 1.2 1.5 2.2 2.2 2.9.3.4.7.7 1 .9.3.2.7.4 1 .4.2 0 .4 0 .6-.1.2-.1.4-.3.5-.5.2-.4.3-1 .3-1.8V8.6c-.1-1.8-.6-2.7-1.2-3.5-.1-.2-.2-.3-.3-.4l-.3-.3c-.1-.2-.2-.3-.2-.5s.1-.3.2-.4c.1-.1.3-.2.5-.2h7.7c.4 0 .6.1.7.2.1.1.2.4.2 1v9.7c0 1.2.5 1.9 1.3 1.9.9 0 1.5-.5 2.7-1.7 2.5-3.1 4.6-6.5 6.1-10.2.1-.3.3-.6.6-.8.3-.2.6-.3 1-.3h5c.7 0 .9.2 1 .3.1.1.1.3 0 .8-1.8 4-4 7.8-6.5 11.3-.2.4-.5.8-.6 1.3 0 .5.2 1 .6 1.5.3.4.9 1 1.5 1.6l.1.1 1.9 1.9c1.6 1.6 3 3.5 4 5.6.2.6.1.8-.1 1-.1.2-.5.3-1.1.3h-4.9c-.4 0-.7-.1-1-.2-.3-.2-.6-.4-1-.8-.3-.3-.6-.7-1-1.1l-.4-.4z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1_8">
          <path fill="#fff" d="M0 0H50.2V32H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Vk;
