import React from "react";

type IframeProps = {
  html: string; // HTML-код, который вы хотите вставить
  width?: string; // Ширина iframe, по умолчанию 100%
  height?: string; // Высота iframe, по умолчанию 100%
};

const Iframe: React.FC<IframeProps> = ({
  html,
  width = "100%",
  height = "100%",
}) => {
  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  React.useEffect(() => {
    if (iframeRef.current) {
      const doc =
        iframeRef.current.contentDocument ||
        iframeRef.current.contentWindow?.document;
      if (doc) {
        doc.open();
        doc.write(html);
        doc.close();
      }
    }
  }, [html]);

  return (
    <iframe
      ref={iframeRef}
      style={{ width, height, border: "none" }}
      title="Custom HTML"
    />
  );
};

export default Iframe;
