import React, {
  ReactNode,
  FC,
  useRef,
  useState,
  useEffect,
  useCallback,
} from "react";

import { useOnClickOutside } from "../../../hooks/use-onclick-outside";
import { Icon } from "../index";

type ModalProps = {
  children: ReactNode;
  isShow: boolean;
  onShow?: (b: boolean) => void;
  width?: number;
};

const Modal: FC<ModalProps> = (props) => {
  const { isShow, children, onShow, width } = props;
  const [open, setOpen] = useState(false);
  const modalRef = useRef();

  useEffect(() => {
    setOpen(isShow);
  }, [isShow]);

  const escFunction = useCallback(
    (event) => {
      if (event.key === "Escape") {
        onShow?.(false);
      }
    },
    [onShow]
  );
  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  function handleClose() {
    setOpen(false);
    onShow?.(false);
  }

  useOnClickOutside(modalRef, () => handleClose());
  if (!open) return <></>;
  return (
    <div className="fixed flex items-center justify-center top-0 left-0 w-screen h-screen z-20  overflow-y-auto">
      <div className="fixed top-0 left-0 w-screen h-screen bg-gray backdrop-filter backdrop-blur-sm bg-opacity-80 z-80" />
      <div
        ref={modalRef}
        className="block  mx-auto my-auto  z-50"
        style={width && { width: "100%", maxWidth: `${width}px` }}
      >
        <div className="w-full text-white base_xl inline-flex justify-end items-end">
          <div
            className=" inline-flex cursor-pointer hover:opacity-80"
            onClick={handleClose}
          >
            <span className="mr-[10px]">esc</span> <Icon name="X" size={20} />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
