export const pages = {
  HOME: "/",
  FILES: "/files",
  PACKAGE: "/package",
  ACCOUNT: "/account",
  HELP: "/help",
  MAKING: "/making",
  VIDEOS: "/videos",
  CONTRACTS: "/contracts",
  LINK: "/link",
  LOGIN: "/login",
};

export const subPages = {
  SETTINGS: `${pages.ACCOUNT}/settings`,
};
