import React, {
  ChangeEvent,
  FC,
  ForwardedRef,
  forwardRef,
  useEffect,
  useState,
} from "react";

import clsx from "clsx";

import { IconButton } from "../index";
import attentionIcon from "../../../resources/icons/attention.svg";

type InputProps = {
  type: "text" | "password" | "email" | "number";
  label: string;
  isCenter?: boolean;
  error?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
};

const Input: FC<InputProps> = forwardRef(
  (props: InputProps, forwarded: ForwardedRef<HTMLInputElement>) => {
    const { type, label, error, isCenter, value, onChange, ...remainingProps } =
      props;
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
      value && setInputValue(value);
    }, [value]);

    const renderError = error?.length && (
      <div className="absolute h-full right-0 top-0 flex items-center z-10">
        <IconButton icon={<img src={attentionIcon} alt="" />} tooltip={error} />
      </div>
    );

    function handleChangeValue(event: ChangeEvent<HTMLInputElement>) {
      setInputValue(event.target.value);
      onChange?.(event);
    }

    return (
      <div className="w-full h-full relative">
        <input
          type={type}
          ref={forwarded}
          onChange={handleChangeValue}
          value={inputValue}
          className={clsx(
            "border-0",
            "outline-0",
            "text-base",
            "placeholder:text-light_gray",
            "box-content",
            "h-full",
            "w-full",
            isCenter && "text-center"
          )}
          placeholder={label}
          {...remainingProps}
        />
        {renderError}
      </div>
    );
  }
);

Input.displayName = "Input";

export default Input;
