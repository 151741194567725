import React, { FC, ForwardedRef, forwardRef } from "react";

import { IconButton } from "../index";
import attentionIcon from "../../../resources/icons/attention.svg";

type TextAreaProps = {
  label: string;
  error?: string;
};

const Textarea: FC<TextAreaProps> = forwardRef(
  (props: TextAreaProps, forwarded: ForwardedRef<HTMLTextAreaElement>) => {
    const { label, error, ...remainingProps } = props;

    const renderErrorMessage = error && (
      <div className="absolute right-0 top-0 bg-white rounded-[100%]">
        <IconButton icon={<img src={attentionIcon} alt="" />} tooltip={error} />
      </div>
    );

    return (
      <div className="relative w-full h-full">
        <textarea
          ref={forwarded}
          placeholder={label}
          className="border-0 outline-0 resize-none text-base w-full box-content h-full placeholder:text-light_gray overflow-hidden"
          {...remainingProps}
        />
        {renderErrorMessage}
      </div>
    );
  }
);
Textarea.displayName = "Textarea";

export default Textarea;
