import { createApi } from "@reduxjs/toolkit/query/react";

import { REQUESTS } from "../../utils/api";

import { baseQueryWithReAuth } from "./baseQuery";

export const messageApi = createApi({
  reducerPath: "messageQuery",
  tagTypes: ["Messages"],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    sendMessages: build.mutation({
      query: (body) => ({
        url: REQUESTS.messages,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Messages"],
    }),
  }),
});

export const { useSendMessagesMutation } = messageApi;
