import { createApi } from "@reduxjs/toolkit/query/react";

import { REQUESTS } from "../../utils/api";

import { baseQueryWithReAuth } from "./baseQuery";

export const userApi = createApi({
  reducerPath: "userQuery",
  tagTypes: ["User"],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getUser: build.query({
      query: () => REQUESTS.users,
      providesTags: () => [{ type: "User", id: "LIST" }],
    }),
    updateUser: build.mutation({
      query: (body) => ({
        url: `${REQUESTS.users}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["User"],
    }),
  }),
});

export const { useGetUserQuery, useUpdateUserMutation } = userApi;
