import React, { FC } from "react";
import clsx from "clsx";

type SkeletonProps = {
  count: number;
  className: string;
};

const Skeleton: FC<SkeletonProps> = (props) => {
  const { count, className } = props;
  return (
    <>
      {new Array(count).fill("_").map((_, index) => (
        <div
          key={index}
          className={clsx("animate-pulse bg-border_gray", className)}
        ></div>
      ))}
    </>
  );
};

export default Skeleton;
