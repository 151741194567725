import React, { FC, ReactNode } from "react";
import clsx from "clsx";

type DividerProps = {
  children?: ReactNode;
  className?: string;
};

const Divider: FC<DividerProps> = (props) => {
  const { children, className } = props;
  return (
    <div className={clsx("w-full h-[1px] bg-border_gray", className)}>
      {children}
    </div>
  );
};

export default Divider;
