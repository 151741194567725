import React, { FC } from "react";

import { Icon } from "../index";

type LoadingProps = {
  title?: string;
};

const Loading: FC<LoadingProps> = (props) => {
  const { title } = props;
  return (
    <div className="w-full flex flex-col justify-center items-center  text-white my-[100px]">
      <Icon name="Loading" color="secondary" size={50} />
      <div className="text-base_xl  text-center text-secondary mt-[5px]">
        {title}
      </div>
    </div>
  );
};

export default Loading;
