import React from "react";

import type { IconProps } from "../icons.type";

function PlayIcon(props: IconProps) {
  const { size = 24 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      fill="none"
      viewBox="0 0 36 41"
    >
      <path
        stroke="currentColor"
        strokeWidth="2"
        d="M33.5 23.098L5.75 39.12c-2 1.154-4.5-.29-4.5-2.598V4.479c0-2.31 2.5-3.753 4.5-2.599L33.5 17.902c2 1.155 2 4.041 0 5.196z"
      ></path>
    </svg>
  );
}

export default PlayIcon;
