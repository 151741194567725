import React from "react";
import { useParams } from "react-router-dom";

import { FileList, Layout } from "../components";
import { Center, Skeleton } from "../components/ui";
import { useGetPackageQuery } from "../store/api/package.api";
import { useGetAttractionQuery } from "../store/api/attraction.api";

const FilesPage = () => {
  const params = useParams();
  const packageQuery = useGetPackageQuery(params.package_id);
  const attractionQuery = useGetAttractionQuery(
    packageQuery.data?.attraction_id,
    {
      skip: !packageQuery.isSuccess,
    }
  );
  return (
    <Layout>
      <Center>
        {attractionQuery.isLoading && (
          <Skeleton count={1} className="my-[35px] w-full h-[350px]" />
        )}
        {attractionQuery.isSuccess && (
          <div className="my-[35px]">
            <FileList
              pack={packageQuery.data}
              attraction={attractionQuery.data}
            />
          </div>
        )}
      </Center>
    </Layout>
  );
};

export default FilesPage;
