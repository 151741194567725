import React, { FC } from "react";

import { IconName } from "../icons/icons.type";
import Icon from "../icons/icon";

import { flatButtonClassName } from "./button.styles";

type FlatButtonProps = {
  icon: IconName;
  title: string;
  onClick?: () => void;
};

const FlatButton: FC<FlatButtonProps> = (props) => {
  const { icon, title, onClick } = props;

  function handleOnClick() {
    onClick?.();
  }

  return (
    <button onClick={handleOnClick} className={flatButtonClassName}>
      <div className="mt-[25px] mb-[14px] w-[32px] h-[32px]">
        <Icon name={icon} size={32} />
      </div>
      <div className="text-base font-semibold text-black group-hover:text-white group-active:text-white">
        {title}
      </div>
    </button>
  );
};

export default FlatButton;
