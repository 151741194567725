import clsx from "clsx";
import React, { FC } from "react";

import * as svgIcons from "./common";
import type { IconName } from "./icons.type";

const icons = { ...Object(svgIcons) };

export type IconList = typeof icons;

type IconProps = {
  className?: string;
  name: IconName;
  color?: string | "white";
  size?: number;
};

const Icon: FC<IconProps> = (props) => {
  const { name, color = "neutral_b", size = 24, ...remainingProps } = props;
  const iconObject = Object(icons);
  const Elem = iconObject[name];
  const currentColor = `text-${color}`;

  const renderIcon = () => {
    return <Elem size={size} fill={color} {...remainingProps} />;
  };

  return (
    <div
      className={clsx(currentColor, "flex justify-center items-center")}
      style={{
        width: `${size}px`,
        maxHeight: `${size}px`,
        display: "flex",
        alignItems: "center",
      }}
      {...remainingProps}
    >
      {renderIcon()}
    </div>
  );
};

export default Icon;
