import clsx from "clsx";

export const playerClassName = clsx(
  "absolute",
  "w-[55px]",
  "h-[55px]",
  "opacity-80",
  "cursor-pointer",
  "hover:opacity-100",
  "transition",
  "bg-black",
  "text-white",
  "flex",
  "pl-[3px]",
  "justify-center",
  "items-center",
  "rounded-[100%]",
  "top-[calc(50%-27px)]",
  "left-[calc(50%-27px)]"
);

export const volumeContainerClassName = clsx(
  "absolute",
  "h-[22px]",
  "hover:h-[120px]",
  "overflow-hidden",
  "transition",
  "hover:bg-text_gray",
  "w-[30px]",
  "flex",
  "flex-col",
  "justify-content",
  "rounded-middle",
  "absolute",
  "bottom-[-11px]"
);

export const bgControlClassName = clsx(
  "absolute",
  "bg-black",
  "opacity-80",
  "flex",
  "justify-between",
  "items-center",
  "py-[15px]",
  "px-[23px]",
  "z-10",
  "bottom-0",
  "translate-y-60",
  "transition h-[50px]",
  "w-full",
  "text-white",
  "group-hover:translate-y-0"
);

export const controlContainerClassName = clsx(
  "absolute",
  "flex",
  "justify-between",
  "items-center",
  "py-[15px]",
  "px-[23px]",
  "z-10",
  "bottom-0",
  "translate-y-60",
  "transition",
  "h-[50px]",
  "w-full",
  "text-white",
  "group-hover:translate-y-0"
);
