import React, { FC } from "react";
import { useForm } from "react-hook-form";

import { toast } from "react-toastify";

import { Button, Divider, Input, Textarea } from "../ui";

import { useAuth } from "../../utils/user-provider";

import { useSendMessagesMutation } from "../../store/api/messages.api";

import { feedbackFormClassName } from "./feedback-form.styles";

type FeedBackFields = {
  email: string;
  name: string;
  message: string;
};

type FeedbackFormProps = {
  title: string;
  withShadow?: boolean;
  package_id?: string;
};

const FeedbackForm: FC<FeedbackFormProps> = (props) => {
  const { title, withShadow, package_id } = props;
  const [sendMessage, { isLoading }] = useSendMessagesMutation();
  const { user } = useAuth();
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<FeedBackFields>({
    defaultValues: {
      name: user?.name,
      email: user?.email,
    },
  });

  function handleSubmitFeedbackForm(data: FeedBackFields) {
    sendMessage({ message: data.message, package_id, date_time: Date.now() })
      .unwrap()
      .then(() => {
        toast.success("Сообщение отправлено!", {
          hideProgressBar: true,
          position: "bottom-right",
        });
        reset();
      });
  }

  return (
    <div>
      <h1 className="mb-[16px] text-center">{title}</h1>
      <form
        onSubmit={handleSubmit(handleSubmitFeedbackForm)}
        className="mx-[16px] tablet:mx-0"
      >
        <div className={feedbackFormClassName(withShadow)}>
          <div className="flex h-[30px]">
            <div className=" flex-1  pr-[5px] py-[2px]">
              <Input
                label="Введите ваш e–mail"
                type="text"
                error={errors.email?.type === "required" && "Поле не заполнено"}
                {...register("email", { required: true })}
              />
            </div>
            <div className="border-l-[1px] mt-[5px] ml-[2px] w-[12px] border-l-light_gray h-[18px] " />
            <div className="flex-1 py-[2px]">
              <Input
                label="Введите ваше имя"
                type="text"
                error={errors.name?.type === "required" && "Поле не заполнено"}
                {...register("name", { required: true })}
              />
            </div>
          </div>
          <Divider />
          <div className="h-[60px] mt-[10px]">
            <Textarea
              label="Введите текст сообщения..."
              error={errors.message?.type === "required" && "Поле не заполнено"}
              {...register("message", { required: true })}
            />
          </div>
        </div>
        <div className="w-full inline-flex justify-center mt-[20px]">
          <Button
            type="submit"
            padding={46}
            label="Отправить"
            size="large"
            disabled={isLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default FeedbackForm;
