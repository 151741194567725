import React from "react";

import logo from "../../../resources/images/dron-selfie-logo.png";

const Footer = () => {
  return (
    <div className="w-full bg-gray min-h-[40px] flex  items-center justify-center text-bg_gray text-large">
      <div className="inline-flex text-text_gray h-full mt-[4px]">
        <span className="text-base">
          Copyright &copy; <b>{new Date(Date.now())?.getFullYear()}</b>
        </span>
        <div className="w-[3px] h-[3px] mt-[6px] mx-[10px] rounded-[100%] bg-black" />
        <img src={logo} width="80px" alt="Drone Selfie" className="mt-[1px]" />
      </div>
    </div>
  );
};

export default Footer;
