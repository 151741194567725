import React, { ChangeEvent, FC } from "react";

import { Icon } from "../index";

import { volumeContainerClassName } from "./player.styles";

type VolumeControlProps = {
  volume: string;
  onChangeVolume: (event: ChangeEvent<HTMLInputElement>) => void;
};

const VolumeControl: FC<VolumeControlProps> = (props) => {
  const { volume, onChangeVolume } = props;
  return (
    <div className="relative w-[30px] ">
      <div className={volumeContainerClassName}>
        <div className=" absolute w-full h-[90] -rotate-90 bottom-[35px] left-[-5px]">
          <input
            type="range"
            value={volume}
            min={0}
            max={1}
            step={0.1}
            onChange={onChangeVolume}
            className="h-[2px] w-[80px]"
          />
        </div>
        <div className="absolute bottom-[5px] left-[8px]">
          <Icon name="Mute" size={11} />
        </div>
      </div>
    </div>
  );
};

export default VolumeControl;
