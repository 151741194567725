import { createApi } from "@reduxjs/toolkit/query/react";

import { REQUESTS } from "../../utils/api";
import { Item } from "../../models/item.type";

import { baseQueryWithReAuth } from "./baseQuery";

export const itemApi = createApi({
  reducerPath: "itemQuery",
  tagTypes: ["Items"],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getItems: build.query({
      query: () => REQUESTS.items,
      providesTags: (result: Array<Item>) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Items" as const, id })),
              { type: "Items", id: "LIST" },
            ]
          : [{ type: "Items", id: "LIST" }],
    }),
    getItem: build.query({
      query: (id: string) => `${REQUESTS.items}/${id}`,
      providesTags: (result: Array<Item>) =>
        result && [{ type: "Items", id: "LIST" }],
    }),
    addItem: build.mutation({
      query: (body) => ({
        url: REQUESTS.items,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Items", id: "LIST" }],
    }),
    deleteItem: build.mutation({
      query: (id: string) => ({
        url: `${REQUESTS.items}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Items", id: "LIST" }],
    }),
    updateItem: build.mutation({
      query: (id: string) => ({
        url: `${REQUESTS.items}/${id}`,
        method: "PUT",
      }),
      invalidatesTags: [{ type: "Items", id: "LIST" }],
    }),
    updateTagItem: build.mutation({
      query: (id: string) => ({
        url: `${REQUESTS.items}/${id}/tags`,
        method: "PUT",
      }),
      invalidatesTags: [{ type: "Items", id: "LIST" }],
    }),
    deleteTagItem: build.mutation({
      query: ({ id, tag_id }) => ({
        url: `${REQUESTS.items}/${id}/tags/${tag_id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Items", id: "LIST" }],
    }),
  }),
});

export const {
  useAddItemMutation,
  useGetItemQuery,
  useGetItemsQuery,
  useDeleteItemMutation,
  useUpdateItemMutation,
  useUpdateTagItemMutation,
  useDeleteTagItemMutation,
} = itemApi;
