import React from "react";

import type { IconProps } from "../icons.type";

function Telegram(props: IconProps) {
  const { size = 24 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      fill="none"
      viewBox="0 0 36 32"
    >
      <g clipPath="url(#clip0_1_4)">
        <path
          fill="currentColor"
          d="M33 0c-.5 0-.9.2-1.3.3-.4.2-2.2.9-5.1 2.1-2.8 1.2-6.5 2.7-10.1 4.3-7.2 3-14.4 6.1-14.4 6.1h.1s-.5.2-1 .5c-.3.2-.6.4-.8.8-.3.3-.5.9-.4 1.4.1 1 .8 1.6 1.2 1.9.5.3.9.5.9.5l6.7 2.3c.3 1 2 6.7 2.5 8 .2.8.5 1.3.8 1.7.1.2.3.4.5.5.1 0 .2.1.3.1h.1-.1H13.2c1.1.3 1.9-.3 1.9-.3l4-3.6 6.6 5.1.1.1c1.4.6 2.8.3 3.5-.3.7-.6 1-1.4 1-1.4V30l5.1-26.2c.1-.6.2-1.3 0-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.5-.9-.6-1.3-.6zm-.1 2.8V3l-5.1 26-.2.2c-.1.1-.2.1-.6 0L18.9 23 14 27.4l1-6.6S27.7 9 28.2 8.5s.4-.6.4-.6c0-.6-.8-.2-.8-.2L11.1 18.1l-8-2.7s7.1-3 14.4-6.1c3.6-1.5 7.3-3.1 10.1-4.3 2.8-1.2 4.9-2.1 5-2.1.2-.1.2-.1.3-.1z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1_4">
          <path fill="#fff" d="M0 0H35.6V32H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Telegram;
