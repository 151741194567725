import React, { FC } from "react";

import { Box, Icon } from "../ui";

type StubBoxProps = {
  message: string;
};
const StubBox: FC<StubBoxProps> = (props) => {
  const { message } = props;
  return (
    <Box className="w-full max-w-[500px] p-[20px] rounded-middle">
      <div className="w-full flex text-x flex-col items-center justify-center font-base">
        <div>
          <Icon name="Info" size={74} />
        </div>
        <div className="text-center w-full max-w-[266px]">{message}</div>
      </div>
    </Box>
  );
};

export default StubBox;
