import React from "react";

const LangSwitch = () => {
  return (
    <div>
      <span className="hidden tablet:block absolute right-0 text-base font-base cursor-pointer transition hover:opacity-90 underline font-semibold right-2 -mt-2">
        Switch to English
      </span>
      <div className="block mr-[15px] ml-[10px] extraMobile:mr-[22px] tablet:hidden text-base font-base cursor-pointer transition hover:opacity-90 underline font-semibold -mt-2">
        Eng
      </div>
    </div>
  );
};

export default LangSwitch;
