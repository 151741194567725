import React from "react";

import type { IconProps } from "../icons.type";

function PlayIcon(props: IconProps) {
  const { size = 24 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      fill="none"
      viewBox="0 0 12 16"
    >
      <mask id="path-1-inside-1_963_478" fill="currentColor">
        <path
          fillRule="evenodd"
          d="M6.25 12L12 16V0L6.25 4H0v8h6.25z"
          clipRule="evenodd"
        ></path>
      </mask>
      <path
        fill="currentColor"
        d="M12 16l-.571.82L13 17.915V16h-1zm-5.75-4l.571-.82-.257-.18H6.25v1zM12 0h1v-1.914L11.429-.82 12 0zM6.25 4v1h.314l.257-.18L6.25 4zM0 4V3h-1v1h1zm0 8h-1v1h1v-1zm12.571 3.18l-5.75-4-1.142 1.64 5.75 4 1.142-1.64zM11 0v16h2V0h-2zM6.821 4.82l5.75-4L11.43-.82l-5.75 4L6.82 4.82zM0 5h6.25V3H0v2zm1 7V4h-2v8h2zm5.25-1H0v2h6.25v-2z"
        mask="url(#path-1-inside-1_963_478)"
      ></path>
    </svg>
  );
}

export default PlayIcon;
