import React from "react";

import type { IconProps } from "../icons.type";

function StarFillIcon(props: IconProps) {
  const { size = 24 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      fill="none"
      viewBox="0 0 16 15"
    >
      <path
        stroke="currentColor"
        d="M8 1.618l1.32 4.064.113.346H14.07L10.612 8.54l-.294.213.113.346 1.32 4.064-3.457-2.512L8 10.438l-.294.213-3.457 2.512L5.569 9.1l.113-.346-.294-.213L1.93 6.028H6.567l.112-.346L8 1.618z"
      ></path>
    </svg>
  );
}

export default StarFillIcon;
