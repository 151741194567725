import React from "react";

import type { IconProps } from "../icons.type";

function Tripadvisor(props: IconProps) {
  const { size = 24 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      fill="none"
      viewBox="0 0 36 32"
    >
      <g clipPath="url(#clip0_1_12)">
        <path
          fill="currentColor"
          d="M26.7 14.6c-1.7 0-3.2 1.4-3.2 3.1 0 1.7 1.4 3.2 3.1 3.2 1.7 0 3.2-1.4 3.2-3.1 0-1.8-1.4-3.2-3.1-3.2zm6-3.4L35.6 8h-6.5c-3.3-2.3-7.3-3.5-11.3-3.5-4.1 0-8.1 1.2-11.4 3.5H0l2.8 3.1c-.4.4-.8.8-1.1 1.3-2.9 3.9-2.1 9.5 1.8 12.4 3.4 2.6 8.2 2.3 11.4-.6l2.9 3.1 2.8-3.1c3.6 3.4 9.2 3.2 12.6-.4 3.2-3.4 3.2-8.6.1-12-.2-.1-.4-.4-.6-.6zM8.9 23.8c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6zm8.9-6c0-4.3-2.9-7.4-6.6-8.9 2.1-1 4.3-1.4 6.6-1.4 2.3-.1 4.5.4 6.6 1.3-3.8 1.4-6.4 4.9-6.6 9zm8.9 6c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6zm-14.6-6c0 1.7-1.4 3.1-3.1 3.1-1.7 0-3.1-1.4-3.1-3.1 0-1.7 1.4-3.1 3.1-3.1 1.7-.1 3.1 1.3 3.1 3.1z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1_12">
          <path fill="#fff" d="M0 0H35.6V32H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Tripadvisor;
