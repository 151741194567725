import React from "react";

import type { IconProps } from "../icons.type";

function StarFillIcon(props: IconProps) {
  const { size = 24 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      fill="none"
      viewBox="0 0 16 15"
    >
      <path
        fill="currentColor"
        d="M8 0l1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.056l-4.702 3.416 1.796-5.528L.392 5.528h5.812L8 0z"
      ></path>
    </svg>
  );
}

export default StarFillIcon;
