import React, { useState } from "react";

import menuSvg from "../../../resources/icons/menu.svg";
import xSvg from "../../../resources/icons/x.svg";
import { Divider, TabMenu } from "../index";
import useWindowSize from "../../../hooks/use-window-size";
import { Center } from "../";

import LangSwitch from "./lang-switch";
import HeaderLogo from "./header-logo";

const Header = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { width } = useWindowSize();
  const renderMenuButton = (
    <img
      src={isOpen ? xSvg : menuSvg}
      alt=""
      className="w-[20px] cursor-pointer"
    />
  );

  return (
    <>
      <div className="flex justify-center h-[100px] z-20">
        <div className="relative max-w-[1040px] w-full flex  justify-between tablet:justify-center items-center">
          <div className="inline-flex justify-start items-center">
            <div
              className="mr-[15px] extraMobile:mr-[42px] ml-[21px] tablet:hidden w-[20px] block"
              onClick={setIsOpen.bind(null, (prev) => !prev)}
            >
              <div>{renderMenuButton}</div>
            </div>
            <HeaderLogo />
          </div>
          <LangSwitch />
        </div>
      </div>
      <Center>
        {width <= 986 ? <TabMenu isShow={isOpen} /> : <TabMenu isShow={true} />}
      </Center>
      <Divider />
    </>
  );
};

export default Header;
