import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/dist/query/react";

import { API_URL, REQUEST_PREFIX } from "../../utils/api";
import { appConfig } from "../../utils/constants";
import { Auth } from "../../models/auth.type";
import { RequestError } from "../../models/error.type";
import findAccessToken from "../../utils/find-access-token";

const baseUrlBuilder = (): string => {
  if (typeof API_URL === "undefined")
    throw Error("Неверный адрес API, или вы забыли прописать API в .env");
  if (typeof REQUEST_PREFIX === "undefined")
    throw Error("Неверная версия API, или вы забыли прописать API в .env");
  return `${API_URL}${REQUEST_PREFIX}`;
};

const baseUrl = baseUrlBuilder();
const prepareHeaders = (access_token: string) => (headers) => {
  headers.set("Authorization", `Bearer ${access_token}`);
  return headers;
};

const authLink = localStorage.getItem(appConfig.AUTH_LINK);
const token = findAccessToken(authLink);
export const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: prepareHeaders(token),
  credentials: "include",
});

export const baseQueryUpdateToken = (token) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: prepareHeaders(token),
    credentials: "include",
  });

export const baseQueryWithoutToken = fetchBaseQuery({ baseUrl });

export const baseQueryWithReAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQueryUpdateToken(token)(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const refreshResult = await baseQueryWithoutToken(
      `/${authLink}`,
      api,
      extraOptions
    );
    if (refreshResult.data) {
      const response = refreshResult.data as Auth | RequestError;
      const detail = response["detail"];
      if (!detail) {
        result = await baseQueryUpdateToken(response["access_token"])(
          args,
          api,
          extraOptions
        );
      }
    }
  }
  return result;
};
