import React, { FC, ReactElement } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import { pages, subPages } from "./utils/routes";
import WelcomePage from "./pages/welcome.page";
import FilesPage from "./pages/files.page";
import SettingsPage from "./pages/settings.page";
import HelpPage from "./pages/help.page";
import { appConfig } from "./utils/constants";
import LoginPage from "./pages/login.page";
import CreateVideoPage from "./pages/create-video.page";
import ContactsPage from "./pages/contacts.page";
import { useGetAuthQuery } from "./store/api/auth.api";
import VideoPage from "./pages/video.page";
import { Loading } from "./components/ui";
import NotFoundPage from "./pages/not-found.page";

type ProtectedRouteProps = {
  children: ReactElement;
};

/**
 * Adds page routing to the project to protect pages using ProtectedRoute
 */

export const Routers = () => {
  return (
    <Routes>
      <Route path={pages.LOGIN} element={<LoginPage />} />
      <Route
        path={pages.HOME}
        element={
          <ProtectedRoute>
            <WelcomePage />
          </ProtectedRoute>
        }
      />
      <Route path={pages.ACCOUNT}>
        <Route
          path={subPages.SETTINGS}
          element={
            <ProtectedRoute>
              <SettingsPage />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path={pages.MAKING}
        element={
          <ProtectedRoute>
            <CreateVideoPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={pages.CONTRACTS}
        element={
          <ProtectedRoute>
            <ContactsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={pages.VIDEOS}
        element={
          <ProtectedRoute>
            <VideoPage />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path={`${pages.VIDEOS}/:package_id`}
        element={
          <ProtectedRoute>
            <VideoPage />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path={`${pages.FILES}/:package_id`}
        element={
          <ProtectedRoute>
            <FilesPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={pages.HELP}
        element={
          <ProtectedRoute>
            <HelpPage />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

/**
 * Creates a protective layer against an unauthorized user.
 * Example:  <Router path="/" component={<ProtectedRoute><JSX ELEMENT /></ProtectedRoute>} />
 */
export const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const link = localStorage.getItem(appConfig.AUTH_LINK);
  const { isError, isSuccess, isLoading } = useGetAuthQuery(
    localStorage.getItem(appConfig.AUTH_LINK)
  );
  if (!link) return <Navigate to={pages.LOGIN} replace />;
  if (isLoading)
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <Loading />
      </div>
    );
  if (isError) return <Navigate to={pages.LOGIN} replace />;
  return isSuccess && children;
};
