import clsx from "clsx";

export const tooltipClassName = clsx(
  "group-hover:block",
  "hidden",
  "text-white",
  "transition",
  "top-[-2px]",
  "absolute",
  "left-[calc(100%+16px)]",
  "border-error",
  "rounded-small",
  "bg-secondary",
  "min-h-[25px]",
  "text-small",
  "font-normal",
  "after:content-['']",
  "after:top-[8px]",
  "after:left-[-10px]",
  "after:absolute",
  "after:border-[5px]",
  "after:border-transparent",
  "after:border-r-[5px]",
  "after:border-r-secondary"
);
