import React, { FC, ReactNode } from "react";

import { tooltipClassName } from "./tooltip.styles";

type TooltipProps = {
  width?: number;
  children: ReactNode;
  description: ReactNode | string;
};

const Tooltip: FC<TooltipProps> = (props) => {
  const { children, description, width } = props;
  return (
    <div className="relative group">
      {children}
      <div
        className={tooltipClassName}
        style={{ width: width ? `${width}px` : "132px" }}
      >
        <div>{description}</div>
      </div>
    </div>
  );
};

export default Tooltip;
