import clsx from "clsx";

export const feedbackFormClassName = (isShadow: boolean) =>
  clsx(
    "bg-white",
    "rounded-middle",
    "w-full",
    "border-[1px]",
    "border-gray_selected",
    "tablet:w-[500px]",
    "px-[10px]",
    isShadow && "shadow-popup"
  );
