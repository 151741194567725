import clsx from "clsx";

export const infoMessageClassName = clsx(
  "relative",
  "w-full",
  "flex",
  "justify-center",
  "items-center",
  "text-center",
  "flex-col",
  "py-5",
  "z-0"
);

export const infoHiddenClassName = clsx(
  "absolute",
  "top-[-8px]",
  "w-4",
  "h-4",
  "border-[1px]",
  "border-border_gray",
  "rounded-[100%]",
  "bg-white",
  "flex",
  "justify-center",
  "items-center",
  "cursor-pointer"
);
