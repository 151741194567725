import React, { FC, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { useOnClickOutside } from "../../../hooks/use-onclick-outside";
import chevronSvg from "../../../resources/icons/chevronDown.svg";
import { pages, subPages } from "../../../utils/routes";

//import { useGetParksQuery } from "../../../store/api/park.api";

import { useGetUserQuery } from "../../../store/api/user.api";

import { useAuth } from "../../../utils/user-provider";

import { SubTabItem, TabMenuList } from "./tab-menu.type";
import {
  itemSubMenuClassName,
  itemTabActiveClassName,
  itemTabMenuClassName,
  tabMenuClassName,
  tabSubMenuClassName,
} from "./tab-menu.styles";

type TabsMenuProps = {
  isShow: boolean;
};

const TabMenu: FC<TabsMenuProps> = (props) => {
  const userQuery = useGetUserQuery("");
  // const { data = [] } = useGetParksQuery("");
  const { logout } = useAuth();
  const { isShow } = props;
  const [activeTab, changeActiveTab] = useState(null);
  const location = useLocation();
  const submenuRef = useRef(null);
  //const menuParksList = data.map((park) => ({
  //  title: `Skypark ${park.name}`,
  //  link: `${pages.VIDEOS}/${park.id}`,
  //}));
  const menuList: TabMenuList = [
    {
      title: "Мои видео",
      link: pages.VIDEOS,
    },
    {
      title: "Помощь",
      link: pages.HELP,
    },
    {
      title: "Контакты",
      link: pages.CONTRACTS,
    },
    {
      title: "Аккаунт",
      link: "/account",
      isRight: true,
      submenu: [
        {
          title: `Добро пожаловать, ${
            userQuery.data?.name || "Временный пользователь"
          }`,
          link: subPages.SETTINGS,
          withAuth: true,
        },
        {
          title: "Настройки аккаунта",
          link: subPages.SETTINGS,
          withAuth: true,
        },
        // Ссылки отключены по ТЗ
        // {
        //   title: "Войти",
        //   link: "/",
        // },
        // {
        //   title: "Регистрация",
        //   link: "/",
        // },
        {
          title: "Выйти",
          withAuth: true,
          link: () => logout(),
        },
      ],
    },
  ];
  useOnClickOutside(submenuRef, changeActiveTab.bind(null, null));

  if (!isShow) return <></>;

  const renderSubmenu = (submenu: Array<SubTabItem>, isLarge: boolean) => (
    <ul ref={submenuRef} className={tabSubMenuClassName(isLarge)}>
      {submenu.map((item, index) =>
        typeof item.link === "function" ? (
          <li
            key={index}
            className={itemSubMenuClassName(false)}
            onClick={item.link}
          >
            {item.title}
          </li>
        ) : (
          <NavLink key={index} className="block" to={item.link}>
            <li
              className={itemSubMenuClassName(item.link === location.pathname)}
            >
              {item.title}
            </li>
          </NavLink>
        )
      )}
    </ul>
  );

  const renderTabMenu = () => (
    <>
      <ul className={tabMenuClassName}>
        {menuList.map((item, index) => {
          const subMenu =
            item.submenu && activeTab === index ? (
              renderSubmenu(item.submenu, item.isRight)
            ) : (
              <></>
            );
          return (
            <li
              key={index}
              className={itemTabMenuClassName}
              onClick={changeActiveTab.bind(null, index)}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  {(item?.link === location.pathname ||
                    "/" + location.pathname.split("/")[1] === item?.link) && (
                    <div className={itemTabActiveClassName} />
                  )}
                  {item.submenu ? (
                    item.title
                  ) : (
                    <NavLink to={item.link}>{item.title}</NavLink>
                  )}
                </div>
                {item.submenu && (
                  <img
                    src={chevronSvg}
                    className="w-2 tablet:hidden"
                    alt="chevron"
                  />
                )}
              </div>
              {subMenu}
            </li>
          );
        })}
      </ul>
    </>
  );
  return <>{renderTabMenu()}</>;
};

export default TabMenu;
