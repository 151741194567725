import { createApi } from "@reduxjs/toolkit/query/react";

import { REQUESTS } from "../../utils/api";
import { Package } from "../../models/package.type";

import { baseQueryWithReAuth } from "./baseQuery";

export const packageApi = createApi({
  reducerPath: "packageQuery",
  tagTypes: ["Package"],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getPackages: build.query({
      query: () => REQUESTS.packages,
      providesTags: (result: Array<Package>) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Package" as const, id })),
              { type: "Package", id: "LIST" },
            ]
          : [{ type: "Package", id: "LIST" }],
    }),
    getPackage: build.query({
      query: (id: string) => `${REQUESTS.package}${id}/`,
      providesTags: () => [{ type: "Package", id: "LIST" }],
    }),
    addPackage: build.mutation({
      query: (body) => ({
        url: REQUESTS.package,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Package", id: "LIST" }],
    }),
    updatePackage: build.mutation({
      query: (body) => ({
        url: `${REQUESTS.package}${body.id}`,
        method: "PUT",
        body,
      }),
      transformResponse: (response: { data: Package }) => response.data,
      invalidatesTags: ["Package"],
    }),
  }),
});

export const {
  useGetPackagesQuery,
  useGetPackageQuery,
  useAddPackageMutation,
  useUpdatePackageMutation,
} = packageApi;
