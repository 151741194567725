import React, { FC } from "react";

type PlayerProps = {
  file: string;
};

import Iframe from "./IFrame";

const Player360: FC<PlayerProps> = (props) => {
  const { file } = props;
  const htmlContent = `
  <head>
    <link rel="stylesheet" href="bivrost_dir/bivrost.css" />
    <script type="text/javascript" src="bivrost_dir/bivrost-min.js"></script>
  </head>
    <bivrost-player style="max-height:100%;" url=${file}></bivrost-player>
  `;

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Iframe html={htmlContent} />
    </div>
  );
};

export default Player360;
