import React, { ReactNode, FC } from "react";

import { ToastContainer } from "react-toastify";

import Header from "../ui/header/header";
import Footer from "../ui/footer/footer";

type LayoutProps = {
  children?: ReactNode;
};
const Layout: FC<LayoutProps> = (props) => {
  const { children } = props;
  return (
    <>
      <Header />
      <div className="  mx-auto w-full min-h-[calc(100vh-200px)]">
        {children}
      </div>
      <ToastContainer />
      <Footer />
    </>
  );
};

export default Layout;
