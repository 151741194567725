import React from "react";

const HeaderLogo = () => {
  const logo = process.env.REACT_APP_LOGO_PATH;
  return (
    <div>
      <img className="w-full max-w-[314.34px]" src={logo} alt="" />
    </div>
  );
};

export default HeaderLogo;
