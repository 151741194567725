import React, { FC } from "react";

import { Player } from "../ui";

import useFileTypeQualifier from "../../hooks/use-file-type-qualifier";

import { boxPreviewClassName } from "./file-preview.styles";

import Player360 from "./player-360";

type BoxPreviewProps = {
  file: string;
};

const BoxPreview: FC<BoxPreviewProps> = (props) => {
  const { file } = props;
  const qualifier = useFileTypeQualifier();

  const checkFileName = (file: string) => {
    if (file.includes(".360.")) {
      return true;
    }
    return false;
  };

  const renderFileView = () => {
    const fileType = file.split(".").pop();

    if (qualifier.isVideo(fileType)) {
      if (checkFileName(file)) {
        return (
          <>
            <Player360 file={file} />
          </>
        );
      } else {
        return (
          <>
            <Player file={file} />
          </>
        );
      }
    } else {
      return (
        <div className="flex items-center justify-center">
          <img src={file} className="object-contain h-full" alt="" />
        </div>
      );
    }
  };

  return <div className={boxPreviewClassName}>{renderFileView()}</div>;
};

export default BoxPreview;
