import React, { FC } from "react";

import { buttonClassName } from "./button.styles";

export type ButtonVariant = "fill" | "outlined";

type ButtonProps = {
  label: string;
  type?: "button" | "submit" | "reset";
  variant?: ButtonVariant;
  isFullWidth?: boolean;
  padding?: number;
  size?: "large" | "medium";
  onClick?: () => void;
  height?: number;
  disabled?: boolean;
  width?: number;
  title?: string;
};
/**
 * Button component
 * @param props
 */
const Button: FC<ButtonProps> = (props) => {
  const {
    label,
    variant = "fill",
    type = "button",
    padding,
    onClick,
    size = "medium",
    height,
    isFullWidth,
    disabled,
    width,
    title,
  } = props;

  const paddingStyle = padding && {
    paddingLeft: `${padding}px`,
    paddingRight: `${padding}px`,
  };
  const heightStyle = height && {
    paddingTop: `${height}px`,
    paddingBottom: `${height}px`,
  };
  const widthStyle = width && {
    width: `${width}px`,
  };

  return (
    <button
      className={buttonClassName(isFullWidth, variant, size)}
      onClick={onClick}
      style={{
        ...paddingStyle,
        ...heightStyle,
        ...widthStyle,
      }}
      title={title}
      type={type}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default Button;
