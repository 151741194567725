import React from "react";

import { useParams } from "react-router-dom";

import { Layout, MediaPack, ConnectWithUs } from "../components";
import { Center, Divider, Skeleton } from "../components/ui";
import poweredByImage from "../resources/images/v74_33.png";
import FeedbackForm from "../components/feedback-form/feedback-form";
import { useGetAttractionsQuery } from "../store/api/attraction.api";
import { useGetUserQuery } from "../store/api/user.api";

const VideoPage = () => {
  const params = useParams();
  const { package_id } = params;
  const attractions = useGetAttractionsQuery(package_id || "");
  const { data = [], isLoading } = useGetUserQuery("");
  const renderPackageList =
    attractions.data && data ? (
      data.packages?.map((pack) => {
        return (
          <MediaPack
            key={pack.id}
            pack={pack}
            attraction={attractions.data?.find(
              (attraction) => attraction.id === pack.attraction_id
            )}
          />
        );
      })
    ) : (
      <></>
    );

  return (
    <Layout>
      <Center>
        <div className="flex justify-center flex-row flex-wrap gap-[20px] mt-[38px]">
          {renderPackageList}
          {isLoading && (
            <Skeleton
              count={4}
              className="w-full h-full max-w-[290px] h-[485px] tablet:max-w-[500px] tablet:h-[260px] rounded-l-small"
            />
          )}
        </div>
      </Center>
      <Center>
        <div className="flex justify-center  my-[50px]">
          <a href="https://droneselfie.tech">
            <img className="w-[148px]" src={poweredByImage} alt="" />
          </a>
        </div>
      </Center>
      <Divider />
      <Center>
        <div className="py-[40px] tablet:flex tablet:justify-center text-text_x font-normal ">
          <FeedbackForm title="Остались вопросы? Напишите нам:" withShadow />
        </div>
      </Center>
      <Divider />
      <Center>
        <ConnectWithUs />
      </Center>
    </Layout>
  );
};

export default VideoPage;
