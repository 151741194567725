import React from "react";
import { useSearchParams } from "react-router-dom";

import clsx from "clsx";

import { Center, Footer, Loading, SimpleHeader } from "../components/ui";
import { appConfig } from "../utils/constants";
import { pages } from "../utils/routes";
import { useGetAuthQuery } from "../store/api/auth.api";
import { ConnectWithUs } from "../components";

const LoginPage = () => {
  const [search] = useSearchParams();
  const loginBoxClassName =
    "my-[40px] text-base_xl font-bold text-center w-full  w-[300px] h-[100px]";
  const link = search.get("link");
  if (link !== null) localStorage.setItem(appConfig.AUTH_LINK, link);
  const { data, isSuccess, isLoading, isError } = useGetAuthQuery(link);

  if (isSuccess && data.access_token) {
    setTimeout(() => (window.location.href = pages.VIDEOS), 1000);
  }
  return (
    <>
      <SimpleHeader />
      <div className="min-h-[calc(100vh-100px)] w-full">
        <Center>
          {isLoading && <Loading title="Идёт авторизация…" />}
          {isSuccess && (
            <div className="w-full flex justify-center items-center flex-col">
              <div className={clsx(loginBoxClassName, "text-primary")}>
                Успешная авторизация! Секунду...
              </div>
            </div>
          )}
          {isError && (
            <div className="w-full flex justify-center items-center flex-col">
              <div className={clsx(loginBoxClassName, "text-error")}>
                Неверная ссылка для авторизации!
              </div>
              <ConnectWithUs />
            </div>
          )}
        </Center>
      </div>
      <Footer />
    </>
  );
};

export default LoginPage;
