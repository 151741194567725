import React, { FC, ReactNode } from "react";
import clsx from "clsx";

type BoxProps = {
  children: ReactNode;
  className?: string;
};
const Box: FC<BoxProps> = (props) => {
  const { children, className } = props;
  return (
    <div className={clsx("shadow-popup bg-white rounded-middle", className)}>
      {children}
    </div>
  );
};

export default Box;
