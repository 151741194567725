import clsx from "clsx";

export const tabMenuClassName = clsx(
  "z-60",
  "relative",
  "inline-flex",
  "bg-white",
  "flex-col",
  "justify-start",
  "w-full",
  "py-[5px]",
  "mx-[24px]",
  "tablet:flex-row",
  "tablet:py-0",
  "tablet:bg-bg_gray",
  "tablet:justify-between",
  "tablet:items-center",
  "tablet:mx-0"
);

export const itemTabMenuClassName = clsx(
  "relative",
  "py-[20px]",
  "text-base_x",
  "font-normal",
  "flex",
  "flex-col",
  "justify-between",
  "cursor-pointer",
  " h-full",
  "tablet:items-center",
  "tablet:flex-row",
  "tablet:py-0",
  "tablet:px-0",
  "tablet:pb-[23px]"
);

export const itemTabActiveClassName =
  "rounded-[100%] w-[6px] h-[6px] bg-primary_light mr-2 tablet:absolute tablet:ml-[-13px]";

export const tabSubMenuClassName = (isRight: boolean) =>
  clsx(
    "relative",
    "border-t-[1px]",
    "mt-[20px]",
    "mb-[-20px]",
    "border-border_gray",
    "top-full",
    "bg-white",
    "py-[8px]",
    "px-[2px]",
    "text-base",
    "font-base",
    "font-semibold",
    "rounded-b-small",
    "tablet:mb-0",
    "tablet:border-none",
    "tablet:absolute",
    "tablet:shadow-popup",
    "tablet:mt-[2px]",
    "z-20",
    isRight && " tablet:right-[calc(100%-70px)]"
  );

export const itemSubMenuClassName = (isActive: boolean) =>
  clsx(
    isActive && "text-primary",
    "w-full",
    "py-[15px]",
    "pl-[10px]",
    "mt-[2px]",
    "mb-[1px]",
    "rounded-small",
    "min-w-[111px]",
    "truncate",
    "tablet:py-[3px]",
    "tablet:pt-[3px]",
    "tablet:pl-[12px]",
    "tablet:px-[12px]",
    "hover:text-primary",
    "hover:bg-gray_selected",
    "cursor-pointer"
  );
