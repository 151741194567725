import React, { FC } from "react";
import clsx from "clsx";

export type TagVariant = 0 | 1 | 2 | 3;

type TagProps = {
  title?: string;
  variant: TagVariant;
};

type StatusList = {
  title?: string;
  color: string;
};

const Tag: FC<TagProps> = (props) => {
  const { title, variant } = props;
  const statusList: Array<StatusList> = [
    { title: "Новый", color: "bg-success" },
    { title: "Готов", color: "bg-primary" },
    { title: "В обработке...", color: "bg-primary" },
    { title: "Просмотренное", color: "bg-primary_lighter" },
  ];
  return (
    <div
      className={clsx(
        "rounded-big text-white text-base font-normal select-none px-[9px] w-min",
        statusList[variant].color
      )}
    >
      {title || statusList[variant].title}
    </div>
  );
};

export default Tag;
