import React, { FC } from "react";

import { Layout } from "../components";
import { Box, Button, Center } from "../components/ui";
import WoWSvg from "../resources/images/v16_131.png";

const WelcomePage: FC = () => {
  return (
    <Layout>
      <Center>
        <div className="w-full flex justify-center mt-[50px]">
          <Box className="max-w-[500px] flex flex-col items-center px-[95px] py-[21px]">
            <div className="h-[131px]">
              <img src={WoWSvg} className="w-[131px]" alt="" />
            </div>
            <div className="pt-[7px]">
              <p className="text-base_l font-normal text-center pb-[13px] leading-[14px]">
                Приветствуем! Здесь вы можете управлять своими медиапакетами.
              </p>
              <p className="text-base_l font-normal text-center leading-[14px]">
                Для доступа к полному функционалу сайта рекомендуем
                зарегистрироваться.
              </p>
            </div>
            <div className="inline-flex flex-col tablet:flex-row py-[25px]">
              <div className="mb-[10px] tablet:mr-[10px] h-full h-fit">
                <Button
                  label="Зарегистрироваться"
                  type="button"
                  variant="fill"
                  size="large"
                  height={15}
                  padding={23}
                  isFullWidth
                />
              </div>
              <div className="w-[150px]">
                <Button
                  label={`Продолжить \r\n с временным аккаунтом`}
                  type="button"
                  height={7}
                  isFullWidth
                  variant="outlined"
                />
              </div>
            </div>
            <div className="text-small font-normal text-center text-gray">
              Напоминаем, что сейчас у вас временный аккаунт. Срок хранения
              ваших ваших ваших ваших медиапакетов ограничен.
            </div>
          </Box>
        </div>
      </Center>
    </Layout>
  );
};

export default WelcomePage;
