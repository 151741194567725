import React, { FC, useState } from "react";
import moment from "moment";

import { NavLink } from "react-router-dom";

import Icon from "../ui/icons/icon";
import { Button, CircleCounter, Modal, Tag } from "../ui";

import { Package } from "../../models/package.type";

import { Attraction } from "../../models/attraction.type";

import { TagVariant } from "../ui/tag/tag";

import { useGetParkQuery } from "../../store/api/park.api";

import { FilePreview } from "../index";

import useHumanTimeLocale from "../../hooks/use-human-time-locale";

import { pages } from "../../utils/routes";

import useFileTypeQualifier from "../../hooks/use-file-type-qualifier";
import VideoImage from "../../resources/images/video.png";
import Video360Image from "../../resources/images/video-360.png";
import PhotoImage from "../../resources/images/photo.png";

import {
  mediaPackClassName,
  mediaPackContentClassName,
  previewMediaContainerClassName,
} from "./media-pack.styles";

type MediaPackProps = {
  pack: Package;
  attraction: Attraction;
};

const MediaPack: FC<MediaPackProps> = (props) => {
  const { pack, attraction } = props;
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const park = useGetParkQuery(attraction.park_id);
  const dayLimit = 14;
  const humanTime = useHumanTimeLocale();
  const dayOffset = moment().diff(moment(pack.date_time_creation), "days");
  const firstFile = pack.package_items[0].file_link;

  const qualifier = useFileTypeQualifier();

  let imagePath;
  if (qualifier.isVideo(firstFile)) {
    if (firstFile.includes(".360.")) {
      imagePath = Video360Image;
    } else {
      imagePath = VideoImage;
    }
  } else {
    imagePath = PhotoImage;
  }

  return (
    <div className={mediaPackClassName}>
      <div
        className="group w-[290px] h-[260px] cursor-pointer overflow-hidden relative rounded-l-small relative"
        onClick={setOpenPreview.bind(null, true)}
      >
        <img
          src={imagePath}
          alt=""
          className="object-cover w-[290px] h-[260px]"
        />
        <div className={previewMediaContainerClassName}>
          <div className=" mb-[60px] text-white   mt-[111px]">
            <Icon
              name="Play"
              className="group-hover:scale-110 transition"
              size={35}
            />
          </div>
        </div>
      </div>
      <div className={mediaPackContentClassName}>
        <div className="pt-[10px] tablet:pt-0">
          <Tag variant={pack.status as TagVariant} />
        </div>
        <h1 className="text-black text-large font-bold mt-[15px] mb-[2px] tablet:mb-[10px]">
          {attraction.name}
        </h1>
        <div className="text-base text-text-gray font-normal mb-[8px]">
          {park.data?.name}
        </div>
        <div className="text-base text-text-gray font-normal  mb-[6px] tablet:pb-[15px]">
          {humanTime.dateMDY(pack.date_time_creation)}
        </div>
        <div className="inline-flex items-center pb-[18px] tablet:pb-[20px] tablet:justify-start tablet:w-full">
          <CircleCounter offset={dayOffset} limit={dayLimit} />
          <span className="ml-[4px] text-base text-text_gray font-normal">
            {humanTime.days(dayLimit - dayOffset)} до удаления
          </span>
        </div>
        <div className="w-[150px]">
          <NavLink to={`${pages.FILES}/${pack.id}`}>
            <Button
              label="Скачать медиапакет"
              variant="fill"
              padding={28}
              isFullWidth
            />
          </NavLink>
        </div>
      </div>
      <Modal isShow={openPreview} onShow={setOpenPreview} width={1024}>
        <FilePreview files={pack.package_items} pack={pack} />
      </Modal>
    </div>
  );
};

export default MediaPack;
