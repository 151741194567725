import clsx from "clsx";

export const mediaPackClassName = clsx(
  "bg-white",
  "flex",
  "flex-col",
  "w-full",
  "h-[485px]",
  "max-w-[290px]",
  "tablet:flex-row",
  "tablet:max-w-[500px]",
  "tablet:h-[260px]",
  "shadow-popup",
  "overflow-hidden",
  "rounded-l-middle",
  "rounded-b-middle"
);

export const mediaPackContentClassName = clsx(
  "w-full",
  "flex",
  "justify-center",
  "flex-col",
  "tablet:w-[200px]",
  "items-center",
  "tablet:justify-start",
  "tablet:items-start",
  "tablet:px-[30px]",
  "tablet:py-5"
);

export const previewMediaContainerClassName = clsx(
  "absolute",
  "top-0",
  "w-[290px]",
  "h-[260px]",
  "transition",
  "flex",
  "flex-col",
  "bg-gray",
  "items-center",
  "group-hover:backdrop-filter group-hover:backdrop-blur-sm bg-opacity-80"
);
