import React from "react";

import { ConnectWithUs, Layout } from "../components";
import { Center, Divider } from "../components/ui";
import FeedbackForm from "../components/feedback-form/feedback-form";

const ContactsPage = () => {
  return (
    <Layout>
      <Center>
        <div className="py-[40px] tablet:flex tablet:justify-center text-text_x font-normal ">
          <FeedbackForm title="Остались вопросы? Напишите нам:" withShadow />
        </div>
      </Center>
      <Divider />
      <Center>
        <ConnectWithUs />
      </Center>
    </Layout>
  );
};

export default ContactsPage;
