import React, { FC, ReactNode } from "react";

type CenterProps = {
  children: ReactNode;
};

const Center: FC<CenterProps> = (props) => {
  const { children } = props;
  return <div className="w-full tablet:max-w-[1024px] mx-auto">{children}</div>;
};

export default Center;
