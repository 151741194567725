import React from "react";

/**
 * Skeleton loading form
 */
import { Skeleton } from "../ui";

const LoadingAccountForm = () => {
  return (
    <div className="text-center flex flex-col items-center">
      <h1 className="text-base_x tablet:text-base_l font-normal mb-[35px] leading-5 ">
        Изменение личных данных
      </h1>
      <Skeleton
        count={4}
        className="w-[213px] h-[28px] mb-[40px] rounded-small"
      />
      <div className="flex flex-col tablet:flex-row gap-3">
        <Skeleton
          count={2}
          className="w-[150px] h-[38px] my-[3px] rounded-middle"
        />
      </div>
    </div>
  );
};

export default LoadingAccountForm;
