import React from "react";

import type { IconProps } from "../icons.type";

function TikTokIcon(props: IconProps) {
  const { size = 24 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height="256"
      viewBox="0 0 256 256"
    >
      <path
        fill="currentColor"
        strokeMiterlimit="10"
        strokeWidth="0"
        d="M34.15 90c-6.499 0-12.627-2.073-17.725-5.995a29.524 29.524 0 01-2.723-2.378c-5.947-5.878-8.94-13.705-8.426-22.041.389-6.3 2.928-12.372 7.149-17.1 5.594-6.266 13.309-9.717 21.724-9.717 1.445 0 2.906.108 4.343.321a2 2 0 011.706 1.979v14.257a2 2 0 01-2.627 1.899 10.754 10.754 0 00-3.38-.544 10.682 10.682 0 00-7.663 3.204 10.612 10.612 0 00-3.074 7.662c.055 3.721 2.014 7.094 5.239 9.022a10.705 10.705 0 004.896 1.503 10.804 10.804 0 003.981-.527 10.688 10.688 0 007.357-10.159L44.948 2a2 2 0 012-2H60.99a2 2 0 012 1.982c.012 1.249.139 2.491.378 3.691a19.696 19.696 0 007.481 11.888 19.59 19.59 0 0011.815 3.973 1.999 1.999 0 012.114 1.996v13.98a2 2 0 01-1.999 2h-.012a37.477 37.477 0 01-14.651-2.957 37.732 37.732 0 01-5.101-2.627l.063 27.565c-.032 7.617-3.045 14.763-8.48 20.136a28.765 28.765 0 01-16.106 8.054A29.646 29.646 0 0134.15 90zm0-53.231c-7.26 0-13.916 2.977-18.74 8.381-3.626 4.061-5.807 9.274-6.141 14.682-.441 7.166 2.132 13.896 7.246 18.949.74.731 1.531 1.422 2.352 2.055C23.256 84.214 28.542 86 34.15 86c1.258 0 2.521-.093 3.755-.276a24.776 24.776 0 0013.88-6.941c4.675-4.62 7.265-10.762 7.293-17.295l-.072-31.372a2 2 0 013.221-1.588 33.58 33.58 0 0018.549 6.925v-9.998a23.514 23.514 0 01-12.337-4.7 23.69 23.69 0 01-8.996-14.3A24.3 24.3 0 0159.09 4H48.948l-.018 57.389c0 6.35-4.06 11.959-10.104 13.956a14.723 14.723 0 01-12.186-1.341c-4.423-2.646-7.109-7.279-7.186-12.396a14.58 14.58 0 014.225-10.528 14.645 14.645 0 0110.512-4.397c.673 0 1.343.046 2.007.137v-9.966a24.62 24.62 0 00-2.048-.085z"
        transform="matrix(.72 0 0 .72 128 128) matrix(3.89 0 0 3.89 -175.05 -175.05)"
      ></path>
    </svg>
  );
}

export default TikTokIcon;
