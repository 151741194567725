import { createApi } from "@reduxjs/toolkit/query/react";

import { REQUESTS } from "../../utils/api";
import { Tag } from "../../models/tag.type";

import { baseQueryWithReAuth } from "./baseQuery";
export const tagApi = createApi({
  reducerPath: "tagQuery",
  tagTypes: ["Tags"],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getTags: build.query({
      query: () => REQUESTS.tags,
      providesTags: (result: Array<Tag>) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Tags" as const, id })),
              { type: "Tags", id: "LIST" },
            ]
          : [{ type: "Tags", id: "LIST" }],
    }),
    addTags: build.mutation({
      query: (body) => ({
        url: REQUESTS.tags,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Tags", id: "LIST" }],
    }),
    getTag: build.query({
      query: () => REQUESTS.tags,
      providesTags: [{ type: "Tags", id: "LIST" }],
    }),
    deleteTag: build.mutation({
      query: (id: string) => ({
        url: `${REQUESTS.tag}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Tags", id: "LIST" }],
    }),
    updateTag: build.mutation({
      query: (id: string) => ({
        url: `${REQUESTS.tags}/${id}`,
        method: "PUT",
      }),
      invalidatesTags: [{ type: "Tags", id: "LIST" }],
    }),
  }),
});

export const {
  useAddTagsMutation,
  useDeleteTagMutation,
  useGetTagQuery,
  useGetTagsQuery,
  useUpdateTagMutation,
} = tagApi;
