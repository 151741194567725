import React from "react";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

import { BrowserRouter } from "react-router-dom";

import { persistor, store } from "./store/store";

import { Routers } from "./routers";
import { AuthProvider } from "./utils/user-provider";

const App = () => (
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}></PersistGate>
      <AuthProvider>
        <Routers />
      </AuthProvider>
    </Provider>
  </BrowserRouter>
);

export default App;
