import React, { FC } from "react";

import { Item } from "../../models/item.type";
// import noPreviewImage from "../../resources/images/noPreview.png";

import useFileTypeQualifier from "../../hooks/use-file-type-qualifier";

import {
  filePreviewClassName,
  filePreviewImageClassName,
} from "./file-preview.styles";

type FileProps = {
  file: Item;
  onClick: (Item) => void;
  isSelect?: boolean;
};

const File: FC<FileProps> = (props) => {
  const { file, onClick, isSelect } = props;
  const qualifier = useFileTypeQualifier();
  const handleFileSelect = () => {
    onClick?.(file);
  };
  let imagePath;
  const noPreviewImage = "images/media.png";
  const changeImageIfExists = (event) => {
    event.target.src = noPreviewImage;
  };

  if (qualifier.isVideo(file.file_link)) {
    if (file.file_link.includes(".360.")) {
      imagePath = "images/video-360.png";
    } else {
      imagePath = "images/video.png";
    }
  } else {
    imagePath = "images/photo.png";
  }

  return (
    <div onClick={handleFileSelect} className={filePreviewClassName(isSelect)}>
      <img
        onError={changeImageIfExists}
        src={imagePath ? imagePath : noPreviewImage}
        className={filePreviewImageClassName}
        alt=""
      />
    </div>
  );
};

export default File;
