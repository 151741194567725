import { createApi } from "@reduxjs/toolkit/query/react";

import { REQUESTS } from "../../utils/api";
import { Ticket } from "../../models/ticket.type";

import { baseQueryWithReAuth } from "./baseQuery";

export const ticketApi = createApi({
  reducerPath: "ticketQuery",
  tagTypes: ["Tickets"],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getTickets: build.query({
      query: () => REQUESTS.packages,
      providesTags: (result: Array<Ticket>) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Tickets" as const, id })),
              { type: "Tickets", id: "LIST" },
            ]
          : [{ type: "Tickets", id: "LIST" }],
    }),
    addTickets: build.mutation({
      query: (body) => ({
        url: REQUESTS.tickets,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Tickets", id: "LIST" }],
    }),
    getTicket: build.query({
      query: (id: string) => `${REQUESTS.packages}/${id}`,
      providesTags: [{ type: "Tickets", id: "LIST" }],
    }),
    deleteTicket: build.mutation({
      query: (id: string) => ({
        url: `${REQUESTS.tickets}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Tickets", id: "LIST" }],
    }),
    updateTicket: build.mutation({
      query: (id: string) => ({
        url: `${REQUESTS.tickets}/${id}`,
        method: "PUT",
      }),
      invalidatesTags: [{ type: "Tickets", id: "LIST" }],
    }),
  }),
});

export const {
  useUpdateTicketMutation,
  useDeleteTicketMutation,
  useAddTicketsMutation,
  useGetTicketsQuery,
  useGetTicketQuery,
} = ticketApi;
