import React, { FC } from "react";

import { FlatButton } from "../ui";
import { buttonList } from "../../utils/data";

const ConnectWithUs: FC = () => {
  const renderIconList = buttonList.map((item, index) => (
    <a key={index} href={item.link} target="_blank" rel="noreferrer">
      <FlatButton icon={item.icon} title={item.label} />
    </a>
  ));

  return (
    <div className="mt-[35px] mb-[90px]">
      <div className="w-full text-center mb-[14px]">
        <h1>Оставайтесь с нами на связи:</h1>
      </div>
      <div className="flex flex-wrap gap-[30px] justify-center">
        {renderIconList}
      </div>
    </div>
  );
};

export default ConnectWithUs;
